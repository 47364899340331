.Container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  > .CoordinatesSetContainer {
    display: flex;
    flex-direction: row;
    > .LatLng {
      margin-right: 2em;
    }
  }
  > .LabelContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0.5em;
    align-items: flex-start;
    justify-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    > .Label {
      font-weight: bold;
    }
  }
}

.Link {
  color: var(--darkblue);
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
