@import '../media';

@mixin font-size(
  $font-size,
  $line-height: normal,
  $font-weight: normal,
  $letter-spacing: normal
) {
  font-size: $font-size + px;
  // font-size: ($font-size / 16) + rem;

  @if $font-weight==normal {
    font-weight: normal;
  } @else {
    font-weight: $font-weight;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: ($letter-spacing / 16) + rem;
  }

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: calc($line-height / 16) + rem;
  }
}
