@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/media';

.ModalContentContainer {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  padding-top: 2em;
  padding-bottom: 0.5em;
  @media all and (max-width: $maxMobile) {
    padding: 0;
  }

  > .DateDisplayContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #ecf6fe 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    @media all and (max-width: $maxMobile) {
      flex-direction: column;
    }
    > .CampaignDateContainer {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      padding: 1em;
      @media all and (max-width: $maxMobile) {
        width: 100%;
        padding: 2px 5px;
      }

      > .CampaignDateTextContainer {
        width: 100%;
        text-align: center;
        letter-spacing: 0px;
        color: #232323;
        opacity: 1;
        display: flex;
        flex-direction: row;
        align-content: center;

        > .CampaignDateClear {
          cursor: pointer;
          @include svg-icon(
            '../../../assets/icons/close_square.svg',
            25px,
            25px,
            black
          );
          margin-left: 1em;
        }
      }

      .CampaignDateTextRightContainer {
        @extend .CampaignDateTextContainer;
        margin-left: 3.5em;
        @media all and (max-width: $maxMobile) {
          margin-left: 0;
        }
      }
    }
  }

  > .DateRangerPickerContainer {
    display: flex;
    flex-direction: column;
  }

  > .SaveButtonContainer {
    margin-top: 1em;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    flex-direction: row;

    > .SaveButton {
      background: $darkblue 0% 0% no-repeat padding-box;
      border-radius: 24px;
      opacity: 1;
      display: flex;
      cursor: pointer;
      width: 20%;
      flex-direction: column;
      @media all and (max-width: $maxMobile) {
        width: 50%;
      }
      > .SaveButtonText {
        color: white;
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }

    > .SaveButtonDisabled {
      @extend .SaveButton;
      background: grey 0% 0% no-repeat padding-box;
      cursor: not-allowed;
    }
  }
}

.Hidden {
  visibility: hidden;
  pointer-events: none;
}
