.noDocumentsText {
  margin-top: 50px !important;
}

.documentsWrapper {
  background: white;
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  padding: 50px;
}

.documentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e5e9ec;
  padding: 5px 20px;
}

.documentNameAndDate {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.documentDate {
  font-family: Yettel-Light;
}

.documentPreview {
  text-decoration: underline;
  cursor: pointer;
}

.documentSave {
  text-decoration: underline;
  margin-left: 20px;
  cursor: pointer;
}
