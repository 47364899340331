@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.TableRow {
  tr:nth-child(odd) {
    background-color: white;
  }
  tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  //padding-left: 20px;
  [data-isidcolumn='true'] {
    display: flex;
    justify-content: space-between;
  }
  .TableData {
    overflow: hidden;
    position: relative;
    text-align: left;
    .CopyIcon {
      display: inline-block;
      &:hover {
        cursor: pointer;
      }
    }
    .DetailsLink {
      text-decoration: underline;
      font-family: Yettel-Bold;
      font-size: 14px;
      &:hover {
        cursor: pointer;
      }
    }
    .Link {
      display: flex;
      align-items: center;
    }
    .TableDataStatus {
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }
  }

  th {
    background: #dbf0ff;
    //padding: 20px;
  }

  //AD STATUSES
  .Status-published {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: $adStatusPublished;
  }
  .Status-submitted {
    @extend .Status-published;
    background-color: $adStatusSubmitted;
  }
  .Status-rejected {
    @extend .Status-published;
    background-color: $adStatusRejected;
  }
  .Status-expired {
    @extend .Status-published;
    background-color: $adStatusExpired;
  }
  .Status-stopped {
    @extend .Status-published;
    background-color: $adStatusStopped;
  }
  .Status-update_submitted {
    @extend .Status-published;
    background-color: var(--pendingstatus);
  }
  .Status-published_update {
    @extend .Status-published;
    background-color: var(--pendingstatus);
  }
  .Status-published_soon_update {
    @extend .Status-published;
    background-color: var(--pendingstatus);
  }
  .Status-code_spent {
    @extend .Status-published;
    background-color: var(--codespentstatus);
  }
  .Status-published_soon {
    @extend .Status-published;
    background-color: var(--publishedsoonstatus);
  }
}
