@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  flex-direction: column;
  @media all and (max-width:$maxMobile) {
    overflow-x: hidden;
  }
  > .Title {
    font-family: $fontPrimary;
    color: var(--darkblue);
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 1.5em;
  }
  > .TabsContainer {
    display: flex;
    width: 100%;
    
    @media all and (max-width:$maxMobile) {
      ul {
        overflow-x: scroll;
        overflow-y: hidden;
        border: none;
        white-space: nowrap;
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
        li {
          color: #4F687D;
          &[aria-selected="true"] {
            color: $darkblue;
          }
        }
      }
    }
  }
}

.TabContent {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  background: white;
  height: 100%;
  width: 100%;
}

.IndividualStoresTabContent {
  @extend .TabContent;
  > .StoreLocationMapContainer {
    width: 62%;
    margin-right: 1em;
    border-radius: 8px;
  }
  > .FieldsContainer {
    display: flex;
    flex-direction: column;
    width: 38%;
    height: 60vh;
    overflow-y: auto;
    margin-bottom: 0.25em;
    padding-right: 2em;
    padding-left: 1em;
    @media all and (max-width:$maxMobile) {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    > .LabelContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 1.5em;
      align-items: flex-start;
      justify-items: flex-start;
      justify-content: flex-start;
      align-content: flex-start;
      > .Label {
        font-weight: bold;
      }
    }
    > .InputContainer {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin-bottom: 1em;
    }
    > .InputContainerHalf {
      @extend .InputContainer;
      width: 50%;
      @media all and (max-width:$maxMobile) {
        width: 100%;
      }
    }
    > .SaveButtonContainer {
      @extend .InputContainer;
      margin-top: 1em;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      margin-bottom: 0em;
    }
  }
}

.react-tabs__tab-list * {
  margin: 0 0em;
}
