@import 'src/assets/styles/mixins';

.HeaderContainer {
  margin-bottom: 1em;
  margin-top: 1em;
  display: flex;
  justify-content: center;

  .Heading {
    line-height: 1;
    text-decoration: none;
    color: var(--darkblue);
    font-weight: bold;
    font-size: 20px;
  }
}

.InputFieldContainer {
  margin-bottom: 2em;
}

.ButtonContainer {
  margin: 0 auto;
  width: 50%;
  @media all and (max-width:$maxMobile) {
    width: 80%;
  }
}

.DeleteAdminContainer {
  margin-top: 1em;
}

.ModalHeader {
  margin-top: 2em;
  margin-bottom: 2em;
}

.BtnNo {
  position: relative;
  margin: 0 auto;
  border: none;
  letter-spacing: 1px;
  color: var(--darkblue);
  line-height: 1;
  background-color: var(--white);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    opacity: 0.75;
  }
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 18px;
  font-family: $fontPrimary;
  cursor: pointer;
}
