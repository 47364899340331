@import 'src/assets/styles/colors';
@import "src/assets/styles/variables";

$slideMaxHeight: calc((100vh - #{$headerHeight} - #{$footerHeight}) * 0.55);

.SliderSection {
  position: relative;

  .PhotoWrapper {
    position: relative;
    max-height: $slideMaxHeight;
    width: 100%;
    text-align: center;
    border-radius: 12px;
    overflow: hidden;
  }

  .Photo {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .DiscountLabel {
    position: absolute;
    top: 1rem;
    left: -1px;
    border-radius: 0 4px 4px 0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: $fontMedium;
    line-height: 1;
    color: $darkblue;
    background-color: $green;
  }

  .LabelText {
    position: absolute;
    top: 3.5rem;
    left: -1px;
    border-radius: 0 4px 4px 0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-family: $fontMedium;
    line-height: 1;
  }
}
