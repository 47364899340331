@import 'src/assets/styles/media';

.PartnerProfileContainer {
  max-width: 30%;
  margin: 10% auto 0;
  @media all and (max-width:$maxMobile) {
    max-width: 80%;
    margin-top: 100px;
    .PartnerProfileButton {
      div:last-child {
        margin-top: 50px;
        width: 187px;
      }
    }
  }
}
