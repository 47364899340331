@import 'src/assets/styles/media';

.Container {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  flex-wrap: wrap;
  align-items: center;
  @media all and (max-width: $maxMobile) {
    padding-top: 0;
  }
}
