@import 'src/assets/styles/media';

.Upload {
  position: relative;
  height: 100%;
  width: auto;
  margin: 0 auto;
  display: contents;
}

