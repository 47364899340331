@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.BuyAdditionalUsersContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > .BuyAdditionalUsersMain {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    > .InputContainer {
      display: flex;
      flex-direction: column;
    }
    span {
      margin-bottom: 1em;
      color: $darkblue;
    }
  }

  span {
    margin-bottom: 1em;
    color: $darkblue;
  }

  .Link {
    text-decoration: underline;
    color: $darkblue;
    font-size: 0.75em;
    cursor: pointer;
  }
}

.PriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .PriceContent {
    display: flex;
  }

  .Price {
    font-weight: bold;
    color: $darkblue;
    margin-right: 1em;
  }

  .PriceSpan {
    margin-bottom: 1em;
  }
}

.AdditionalUserText {
  text-align: left;
  margin-bottom: 0;
}

.AddAdditional {
  background-color: $locationblue;
  position: relative;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1em;
}

.MaxExceededText {
  margin-left: 1em;
  display: flex;
  justify-content: center;
  align-content: center;
  width: fit-content;
  flex-direction: column;
  letter-spacing: 0px;
  color: #ff1e1e;
  opacity: 1;
  font-size: 80%;
}

.LinkContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  margin-top: 1em;
}
