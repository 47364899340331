@import 'src/assets/styles/variables';

.Break {
  width: 1px;
  height: 12px;
  border-left: 1px solid #efefef;
  margin: 0 10px;
}

.Lang {
  font-family: $fontLight;
  color: #efefef;
  font-size: 16px;
  line-height: 15px;
  cursor: pointer;
}

.LangActive {
  @extend .Lang;
  color: #b4ff00;
}

.LangContainer {
  display: flex;
  align-items: baseline;
}
