@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.MobileHeader {
  background: $darkblue;
  display: flex;
  align-items: center;
  height: 50px;
}

.MobileHeaderLogo {
  color: $green;
  font-family: $fontBold;
  cursor: pointer;
  font-size: 1.25em;
  padding-left: 17px;
  flex: 0 75%;
  display: flex;
  align-items: center;
}

.MobileHeaderMenu {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.MobileHeaderClickable {
  cursor: pointer;
  flex: 1;
}

.HamburgerMenu, .cancelMenu, .MobileHeaderProfileImg {
  width: 28px;
  height: auto;
  margin: 0;
}
