@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.CancelLinkContainer {
  margin-top: 2em;

  .CancelApprovalLink {
    text-decoration: underline;
    cursor: pointer;
  }
}

.ButtonModalContainer {
  display: flex;
  justify-content: space-around;
  @media all and (max-width:$maxMobile) {
    flex-direction: column;
    gap: 1em;
  }
}

.ModalHeader {
  margin: 2em;
}

.BtnNo {
  position: relative;
  margin: 0 auto;
  border: 2px solid var(--darkgrey);
  letter-spacing: 1px;
  color: var(--darkblue);
  line-height: 1;
  background-color: var(--white);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    opacity: 0.75;
  }
}

.MessageContainer {
  display: flex;
  justify-content: center;
  background: $buttonGrey;
  border-radius: 24px;
  padding: 0.5em;
  margin-top: 2em;
}
