.ad-detail-tabs {
  border-bottom: 1px solid #c8c8c8 !important;
  .react-tabs__tab {
    color: #99a7b3 !important;
    font-family: 'Yettel-Bold' !important;
    font-size: 16px !important;
  }
  .react-tabs__tab--selected {
    color: var(--darkblue) !important;
  }
}
