@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/media';

$uploadContainerWidth: 19.140625vw;
$uploadContainerHeight: calc(#{$uploadContainerWidth} / 2);

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
  background-color: transparent;
  @media all and (max-width: $maxMobile) {
    width: 90%;
  }
  .FileInputContainer {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    @media all and (max-width: $maxMobile) {
     flex-flow: row wrap;
    }
    .FileInput {
      display: flex;
      flex-direction: column;
      @media all and (max-width: $maxMobile) {
        flex: 0.50;
        min-width: 37.3vw;
        margin: 10px;
      }
      .CropButtons {
        margin-top: 1rem;
        display: flex;
        justify-content: space-around;
        font-size: 0.7rem;

        > .BtnContainer {
          display: flex;
          width: 100%;
        }
      }
    }
  }

  .UploadContainer {
    background: $fileUploadBackgroundGrey 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    opacity: 1;
    width: $uploadContainerWidth; // was 294px
    height: $uploadContainerHeight; //was 294px
    @media all and (max-width: $maxMobile) {
      width: 100%;
      height: 100%;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;

    .UploadFileDescription {
      font-weight: bold;
      margin-top: 8px;
      font-size: 16px;
    }

    img {
      width: $uploadContainerWidth; // was 294px
      height: $uploadContainerHeight; // was 294px
      margin-right: 0; // overrides Header.module.scss img property
      @media all and (max-width: $maxMobile) {
        width: 100%;
        height: 100%;
      }
    }

    .Description {
      font-size: 14px;
    }

    .PlusIcon {
      height: 32px;
      width: 32px;
    }
  }

  margin: 0 auto;

  > .PageTitleContainer {
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    text-align: center;

    > .PageTitle {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      margin: 2rem 0 0;
      color: $darkblue;
      opacity: 1;
    }
  }

  .DisabledForm {
    label, input, .RegularLabel, .SectionTitle, .UserSegmentsLabel {
      color: #00234070;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  .FormContainer {
    border-radius: 10px;
    background-color: white;
    margin-top: 2em;

    input[type='checkbox'] {
      display: none;

      + .FormHeaderText {
        transition: all 0.2s;
      }
    }

    input[type='checkbox']:checked {
      height: 0;
      margin-top: 0;
      transition: all 0.3s;

      ~ .FormContent {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;
      }

      + .FormHeaderText {
        &::after {
          transition: all 0.2s;
          border-width: 10px 8px 0 8px;
          border-color: #000000 transparent transparent transparent;
        }
      }
    }

    .FormHeaderText {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      background-color: var(--lightblue);
      color: var(--darkblue);
      border-radius: 0.5rem;
      padding: 1rem 1rem;
      text-decoration: none;
      cursor: pointer;
      margin: 15px 10px 20px;

      h3 {
        margin: auto 0;
        text-align: left;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1;
        text-decoration: none;
        user-select: none;
      }

      &:after {
        transition: all 0.2s;
        margin: auto 0;
        content: '';
        top: 1.2rem;
        right: 1rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #000000 transparent;
      }
    }
    .SectionTitle {
      margin-bottom: 15px;
      font-size: 18px;
      text-align: left;
      display: block;
    }

    .DisabledForm {
      label {
        color: #00234070;
      }
    }
    .FormContent {
      max-height: 300vw;
      opacity: 1;
      margin-bottom: 2rem;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;

      .adTypeContainer {
        width: 25%;
      }
      @media all and (max-width: $maxMobile) {
        max-height: unset;
      }
      .SelectContainer {
        @media all and (max-width: $maxMobile) {
          margin-bottom: 1rem;
        }
      }
      .PackageContainer {
        display: flex;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
        }
        > .PlanPick {
          display: flex;
          flex-direction: row;
          width: 60%;
          margin-bottom: 2rem;
          margin-right: 10px;
          @media all and (max-width: $maxMobile) {
            width: 100%;
            margin-bottom: 0;
          }
          > .InnerPlanPick {
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        > .OfferType {
          display: flex;
          flex-direction: row;
          margin-bottom: 2rem;
          margin-right: 2em;
          width: 25%;
          > .InnerOfferType {
            width: 100%;
          }
        }
      }

      > .FlexOfferSelectionLine {
        @extend .OfferSelectionLine;
        display: flex;
        flex-direction: row;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
        }
        > * {
          margin-right: 2em;
          width: 75%;
          @media all and (max-width: $maxMobile) {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      > .OfferSelectionLine {
        width: 100%;
        margin-bottom: 1em;
        margin-right: 0;

        > .AdditionalFieldUrlContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-right: -0em;
          @media all and (max-width: $maxMobile) {
            margin-top: 1rem;
          }
          > .CouponsContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
          }
        }

        > .OfferTermsContainer {
          min-height: 150px;
          display: flex;
          margin-bottom: 2rem;
        }

        > .OfferDescriptionContainer {
          min-height: 250px;
          display: flex;
          margin-bottom: 2rem;
          margin-top: 2rem;
        }
      }

      > .OfferTypeBtnsContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        // TODO remove this temporary measure after test period
        display: none;

        > * {
          margin-left: 20px;
        }
      }

      > .LocationpPick {
        display: flex;
        flex-direction: row;
        width: 50%;
        margin-bottom: 2rem;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
          width: 100%;
        }
        > .SelectContainer {
          width: 50%;
          @media all and (max-width: $maxMobile) {
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        > .LinkContainer {
          width: fit-content;
          height: fit-content;
          flex: 1;
          flex-direction: column;
          margin-top: 3rem;
          @media all and (max-width: $maxMobile) {
            margin-top: 0;
          }
          > .Link {
            text-align: left;
            color: var(--blue);
            cursor: pointer;
            opacity: 1;
            margin-bottom: -1.2rem;
            font-family: 'Yettel';
            font-size: 18px;
            @media all and (max-width: $maxMobile) {
              margin-top: 1rem;
              margin-bottom: 1rem;
              display: block;
            }
          }
        }
      }

      > .UserLocationContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
        }
        > .UserLocationDropdownContainer {
          flex:0 1 25%;
          margin-right: 1rem;
          margin-bottom: 1rem;
          @media all and (max-width: $maxMobile) {
            margin-bottom: 0;
            flex:0 1 25%;
          }
        }
      }

      > .CategoryPick {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 2rem;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
        }
        > .CategoryContainer {
          width: 35%;
          @media all and (max-width: $maxMobile) {
            width: 100%;
          }
        }

        > .CategorySelectedOptions {
          margin-left: 10px;
          width: 60%;
          @media all and (max-width: $maxMobile) {
            width: 100%;
            margin-top: 15px;
          }
        }
      }

      > .OfferSubform {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 2rem;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
          margin-bottom: 0;
        }
        > .OfferDataContainer {
          margin-right: 1%;
          width: 24%;
          @media all and (max-width: $maxMobile) {
            width: 100%;
          }
        }
      }

      > .AdTypeButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        @media all and (max-width: $maxMobile) {
          flex-direction: column;
          > div:nth-of-type(2) {
            position: relative;
            top: 515px;
          }
        }
      }
    }
  }
}

.SubmitContainer {
  position: relative;
  top: 0;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  > .SubmitBtn, > .SaveBtn {
    position: relative;
    margin: 0 auto;
    background: none;
    border: none;
    font-size: 1.125rem;
    letter-spacing: 1px;
    color: var(--white);
    line-height: 1;
    background-color: var(--darkblue);
    border-radius: 24px;
    padding: 1rem 3rem;
    transition: opacity 0.3s;
    cursor: pointer;
    @media all and (max-width: $maxMobile) {
      padding: 1rem 2rem;
    }
    &.SaveBtn {
      background-color: transparent;
      border:solid 2px var(--darkblue);
      color: var(--darkblue);
    }
    &:hover {
      opacity: 0.75;
    }
  }

  > .SubmitBtnDisabled {
    @extend .SubmitBtn;
    background-color: var(--lightgrey);
  }
}

.OfferSelectionColumn {
  display: flex;
  flex-direction: column;

  > .Label {
    position: relative;
    flex-basis: 100%;
    margin-bottom: -0.25rem;
    text-align: left;
    color: $darkblue;
  }

  > .Em {
    position: relative;
    flex-basis: 100%;
    font-size: 0.875rem;
    margin-top: 1rem;
    margin-bottom: -0.25rem;
    text-align: left;
    font-style: normal;
    color: $darkblue;
  }
}

.OfferSegmentsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  @media all and (max-width: $maxMobile) {
    flex-direction: column;
  }
  > * {
    margin-right: auto;
  }
}

.UserSegmentsContainer {
  display: flex;
  flex-direction: column;
  margin-right: 1%;
  width: 24%;
  margin-bottom: 1em;
  @media all and (max-width: $maxMobile) {
    width: 100%;
  }
  > .UserSegmentsLabel {
    margin-bottom: 1rem;
    text-align: left;
    letter-spacing: 0;
    color: #0c1026;
    height: 2rem;
    @media all and (max-width: $maxMobile) {
      height: auto;
    }
  }

  > .AgeContainer {
    display: flex;
    flex-direction: row;

    > * {
      padding-left: 1.25px;
      padding-right: 1.25px;
    }
  }
}

.RegularLabel {
  margin-bottom: 0.4rem;
  text-align: left;
  height: 3em;
  color: $darkblue;
  @media all and (max-width: $maxMobile) {
    height: 1em;
  }
}

.DisabledForm {
  .RegularLabel, .UserSegmentsLabel {
    color: #00234070;;
  }
}

.LowMarginLabel {
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  color: $darkblue;
}

.StepsRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  justify-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;

  > .OuterStepsContainer {
    width: 50%;
    height: 152px;
    @media all and (max-width: $maxMobile) {
      width: 100%;
    }
  }
  > .OuterStepsContainerSmall {
    width: 25%;
  }
}

.CampaignReachOuterContainer {
  margin-top: 2em;
}

.CenteredContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  justify-items: center;

  > .CenteredBtnContainer {
    display: flex;
    flex-direction: row;
    margin-left: 25%;
    margin-right: 25%;
    @media all and (max-width: $maxMobile) {
      margin: 0 auto;
    }
  }
}

.CenteredContentWithTopMargin {
  @extend .CenteredContent;
  margin-top: 2rem;
}

.VerticalCenteredContent {
  @extend .CenteredContent;
  flex-direction: column;
}

.CenteredContentChange {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  justify-items: center;
  @media all and (max-width: $maxMobile) {
    flex-direction: column;
  }
  .Left {
    flex: 1;
    margin: auto 0;
    @media all and (max-width: $maxMobile) {
      margin: 1rem 0;
    }
  }

  &:after {
    flex: 1;
    content: '';
  }
}

.BackToCreateOffer {
  margin-top: 1em;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0;
  color: var(--darkblue);
  opacity: 1;
  cursor: pointer;
  font-family: 'Yettel';
  font-size: 18px;
}

.AdPreviewContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-content: center;

  > .OfferPreviewContainer {
    margin-bottom: 2rem;
  }
}

.CropBtnContainer {
  margin-top: 1rem;
}

.CropEditBtnContainer {
  display: flex;
  flex-direction: row;
  > * {
    font-size: 0.78125vw !important;
    @media all and (max-width: $maxMobile) {
      font-size: 2vw !important;
    }
  }
}

.CropperContainer {
  width: fit-content;
  display: flex;
  height: fit-content;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.Cropper {
  min-width: 300px;
  min-height: 300px;
  max-height: fit-content;
  max-width: fit-content;
}

.Hidden {
  visibility: hidden;
  pointer-events: none;
  height: 0;
}

.Required {
  color: var(--requiredstar);
}

.UploadCodesContainer {
  width: 100%;
}
