@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.Container {
  background-color: #f2f2f2;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  > .Section {
    margin-top: 3em;
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 60%;
    padding-top: 0.7em;
    box-shadow: 0px 3px 6px #00000029;
    padding-bottom: 3.5em;
    @media all and (max-width:$maxMobile) {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 2em;
      width: 90%;
      box-shadow: none;
    }

    > .SectionContent {
      display: flex;
      flex-direction: column;
      width: 60%;
      @media all and (max-width:$maxMobile) {
        width: 100%;
      }
      justify-self: center;
      align-self: center;
      > .Title {
        font: $fontPrimary;
        font-size: 18px;
        color: var(--darkblue);
        align-self: flex-start;
        justify-self: flex-start;
        margin-top: 1em;
        margin-bottom: 1em;
        @media all and (max-width:$maxMobile) {
          text-align: left;
          width: 100%;
          margin: 0;
          padding: 1em;
          background-color: $background;
          font-size: 18px;
          font-family: 'Yettel-Bold';
        }
      }
      > .MainContent {
        display: flex;
        flex-direction: column;
        height: 50vh;
        overflow-y: auto;
        width: 100%;
        @media all and (max-width:$maxMobile) {
          height: auto;
          padding: 1em;
        }
        > .Row {
          display: flex;
          flex-direction: row;
          width: 100%;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          border-bottom: 1px solid #b8b8b8;
          > .Left {
            width: 100%;
            text-align: left;
            align-self: flex-start;
            margin-top: 0.3em;
            @media all and (max-width:$maxMobile) {
              padding-right: 1em;
            }
          }
          > .Right {
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            width: fit-content;
            text-align: right;
            @media all and (max-width:$maxMobile) {
              align-self: center;
            }
            
          }
        }
      }

      > .MainContentEmpty {
        @extend .MainContent;
        height: 0vh;
      }
    }
  }
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 1em;
  font-family: 'Yettel';
  cursor: pointer;
  @media all and (max-width:$maxMobile) {
    font-size: 18px;
    font-family: 'Yettel-Bold';
  }
}

.AddStoreLocationContainer {
  margin-top: 1em;
  > .Plus {
    margin-right: 0.25em;
  }
}

.Edit {
  height: 32px !important;
  cursor: pointer;
  margin-right: 0.2em;
}

.Delete {
  @extend .Edit;
  margin-left: 1.3em;
}
