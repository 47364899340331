@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: transparent;
  justify-items: center;
  align-items: center;

  .FormContainerTop {
    border-radius: 10px;
    background-color: white;
    width: 80%;
    margin-top: 0.5em;
    @extend .FormContent;
    padding-bottom: 2em;
  }

  > .FormContainer {
    border-radius: 10px;
    background-color: white;
    margin-top: 2em;
    width: 80%;
  }

  .FormContent {
    max-height: 300vw;
    opacity: 1;
    margin-bottom: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s;

    > .MainContentContainer {
      display: flex;
      flex-direction: row;
      width: 100%;

      .CenteredTabsContainer {
        width: 100%;
        text-align: left;
      }
    }
  }

  .BackToOffersContainer {
    margin-top: 2em;
    border-radius: 10px;
    width: 80%;
    display: flex;
    cursor: pointer;
    align-items: center;

    .Arrow {
      height: 1em;
      margin-right: 0.5em;
    }
  }
}

.AdDetailTabContainer {
  width: 100%;
  padding: 2rem;
  background-color: var(--white);

  .AdOverviewContainer {
    display: flex;
    justify-content: space-between;

    .AdPreviewPart {
      padding: 0 1rem;
    }

    .AdTypePreviewContainer {
      text-align: left;
      flex-basis: 30%;
    }

    .AdditionalDetails {
      display: flex;
      flex-direction: column;
      width: 100%;

      .Element {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $colorBorder;
        padding: 5% 0 5% 0;

        .ElementTitle {
          text-align: left;
          color: #002340;
          font-weight: 900;
        }

        .ElementText {
          color: #002340;
          text-align: left;
        }

        .Link {
          text-decoration: underline;
          color: var(--darkblue);
          font-size: 18px;
          font-family: $fontPrimary;
          cursor: pointer;
          margin: auto 0;
        }

        .DownloadPictures {
          display: flex;

          .Link {
            text-decoration: underline;
            color: var(--darkblue);
            font-size: 18px;
            font-family: $fontPrimary;
            cursor: pointer;
            margin: auto 0;
          }

          .DownloadImage {
            cursor: pointer;
            margin-right: 0.5em;
            width: 32px;
            height: 32px;
          }
        }

        .Label {
          display: flex;
          align-items: center;
          margin-bottom: 2%;
        }

        .DropdownContainer {
          width: 25%;
        }

        .GoodQualityIcon {
          width: 2em;
          height: 2em;
          margin-left: 2%;
        }
      }

      .ElementCenterText {
        @extend .Element;
        align-items: center;
      }
    }
  }
}

.AdPreviewContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  flex-basis: 40%;

  .InnerContainer {
    padding-bottom: 2em !important;
  }
}

.AdStatisticsContainer {
  display: flex;
  flex-direction: row;
  justify-items: flex-end;
  flex-basis: 30%;

  .TextAreaInputField {
    width: 100%;
    margin: 0 0 2rem 0;
    height: 150px;
  }

  .Element {
    display: flex;
    flex-direction: column;
    padding: 5% 0 5% 0;

    .ElementTitle {
      text-align: left;
      color: #002340;
      font-weight: 900;
    }

    .ElementText {
      color: #002340;
      text-align: left;
    }
  }
}

.CancelLinkContainer {
  margin-top: 2em;

  .CancelApprovalLink {
    text-decoration: underline;
    cursor: pointer;
  }
}

.ButtonModalContainer {
  display: flex;
  justify-content: space-around;
}

.ModalHeader {
  margin-top: 2em;
  margin-bottom: 2em;
}

.BtnNo {
  position: relative;
  margin: 0 auto;
  border: none;
  letter-spacing: 1px;
  color: var(--darkblue);
  line-height: 1;
  background-color: var(--white);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    opacity: 0.75;
  }
}

.TextAreaInputField {
  min-height: 250px;
  display: flex;
  margin: 2rem 1em;
}

.MessageContainer {
  display: flex;
  justify-content: center;
  background: $buttonGrey;
  border-radius: 24px;
  padding: 0.5em;
  margin-top: 2em;

  > img {
    margin-top: 0.2em;
    margin-right: 0.5em;
  }
}

.Border {
  border-bottom: 1px solid #b8b8b8;
  margin-bottom: 0.5em;
}

.TopBorder {
  border-top: 1px solid #b8b8b8;
  margin-top: 0.5em;
}

.TopPadding {
  padding-top: 0.5em;
}

.AdSettingsContainer {
  display: flex;
  justify-content: space-between;

  .ItemsColumn {
    display: flex;
    flex-direction: column;
    width: 32%;
  }

  .Item,
  .DoubleItem {
    border-bottom: 1px solid $lightgrey;
    padding: 0.5rem 0;
    column-break-inside: avoid;
    display: inline-block;
    width: 95%;

    p {
      margin-bottom: 0;
    }

    .ItemTitle {
      font-family: $fontMedium;
    }

    .ItemText {
      font-family: $fontRegular;
    }
  }

  .DoubleItem {
    column-count: 2;
  }
}

.PEventsNone {
  pointer-events: none;
}

.Link {
  color: var(--darkblue);
  font-size: 18px;
  font-family: $fontPrimary;
  cursor: pointer;
}

.EditAdText {
  margin-top: 0.5em;
  text-decoration: underline;
  cursor: pointer;
}
