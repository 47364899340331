@import '../all-ads/AllAds.module';
@import '../../../components/UI/checkbox/Checkbox.module';

.AdListOuterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 30px;
  gap: 30px;
}

.InputFieldContainer {
  display: flex;
  width: 350px;
  margin-bottom: 0.5em;
}

.TextAreaContainer {
  @extend .InputFieldContainer;
  min-height: 300px;
}

.CheckboxContainer {
  @extend .InputFieldContainer;
  gap: 10px;
}

.InputLabel {
  font-size: 18px;
  text-align: start;
  display: block;
}

.FileInput {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  padding: 25px;
  width: 350px;
  gap: 10px;

  img {
    margin-right: 0;
    height: 190px;

    &[alt='icon'] {
      height: 40px;
    }
  }
}

.UploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;

  &:hover {
    img:not(.DeleteButton) {
      -webkit-filter: brightness(0.55);
      filter: brightness(0.55);
    }
  }

  .DeleteButton {
    position: absolute;
    top: 5px;
    right: -50px;
    height: 36px;
    width: 36px;
    cursor: pointer;
    filter: brightness(0);
  }
}

.NavigateBack {
  margin-top: 2em;
  margin-left: 2em;
  border-radius: 10px;
  width: 80%;
  display: flex;
  cursor: pointer;
  align-items: center;

  .Arrow {
    height: 1em;
    margin-right: 0.5em;
  }
}

.DeleteBanner {
  color: $colorDangerText;
  cursor: pointer;
  text-decoration: underline;
}

.Description {
  text-wrap: auto;
}
