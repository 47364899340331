.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  align-content: center;
  width: 80%;
  margin-top: 2em;
  margin: 0 auto;
  > .InputFieldContainer {
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    width: calc(100%/6);
  }
}

.FieldMargin {
  margin-bottom: 1em;
}

.ExportCsvContainer {
  display: flex;
  align-items: center;
  margin-top: 2em;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    text-decoration: underline;

    img {
      margin-right: 0.5em;
    }
  }
}
