@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 312px;
  width: 576px;
  margin: 0 auto;
  .Checkmark {
    margin: 0 auto;
    height: 51px;
  }
  .Button {
    width: 168px;
    margin: 0 auto;
  }
}
