@import '../assets/styles/variables';
@import '../assets/styles/mixins/index';

.SvgIcon {
  @include inline-flex-center;
  fill: $colorText;
  position: relative;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.SvgFlag {
  @include inline-flex-center;
  position: relative;

  svg {
    width: 1.6rem;
    height: 1.2rem;
  }
}

.Image {
  display: flex;
  padding: 0;
  margin: 0 0 2.25rem 0;
  width: 100%;
}

.Figure,
.Picture {
  margin: 0;
  padding: 0;
  width: 100%;
}

.Figure {
  text-align: center;
}

.Img {
  display: flex;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
}
