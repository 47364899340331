@import 'src/assets/styles/colors';
table {
  border-collapse: collapse;
}

th,
td {
  border: none;
}
.Table {
  width: 100%;
  text-align: left;
  thead {
    background-color: #f2f4f5;
  }
  tr {
    height: 60px;
    border-top: #f2f4f5 2px solid;
  }
  .FirstColumn {
    width: 440px;
    padding-left: 30px;
    .EditLinkContainer {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .FourthColumn {
    text-align: right;
    padding-right: 30px;
  }
  .Bold {
    font-weight: bold;
  }
}
