.Container {
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
  > .AdditionalPackagesContent {
    margin-top: 4em;
    display: flex;
    flex-direction: row;
    margin-left: 2em;
    margin-right: 2em;
    justify-content: center;
    justify-items: center;
    align-items: center;
    justify-items: center;
    > * {
      margin: auto;
    }
  }
}
