@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.CheckboxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .OptionGroup {
    padding: 5px 15px;

    input[type='checkbox'] + label:before {
      content: url(../../../../src/assets/icons/expand.svg);
    }  
    
    input[type='checkbox']:checked + label:before {
      content: url(../../../../src/assets/icons/colapse.svg);
      cursor: pointer;
    }
  }

  .Option {
    padding: 5px 30px;

    input[type='checkbox'] + label:before {
      content: url(../../../../src/assets/icons/empty.svg);
    }  
    
    input[type='checkbox']:checked + label:before {
      content: url(../../../../src/assets/icons/check-white.svg);
      cursor: pointer;
    }
  }

  .Basic {

    input[type='checkbox'] {
      display: none;    
    }  

    input[type='checkbox'] + label:before {
      content: url(../../../../src/assets/icons/checkmark_unchecked.svg);
    }  

    input[type='checkbox']:checked + label:before {
      content: url(../../../../src/assets/icons/checkmark.svg);
      cursor: pointer;
    }
  }


  input[type='checkbox'] + label:before {
    position: relative;
    left: 0px;
    top: 0.4em;
    position: relative;
    margin-right: 0.5rem;
    cursor: pointer;
    height: 30px;
  }

  .Checkbox {
    display: none;
  }

  .CheckboxLabel {
    text-align: left;
    letter-spacing: 0px;
    color: #002340;
    opacity: 1;
    margin-bottom: 0.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .CheckboxLabelDisabled {
    @extend .CheckboxLabel;
    color: var(--lightgrey);
  }
}
