@import "src/assets/styles/colors";

@mixin rich-text-button() {
  background: $white;
  color: #888;
  font-size: 18px;
  border: 0;
  padding-top: 5px;
  vertical-align: bottom;
  height: 34px;
  width: 36px;
  cursor: pointer;
  &:hover {
    background: $bgfeatured;
  }

  &:disabled {
    cursor: not-allowed;
    img {
      filter: opacity(0.5);
    }
  }
}
