.TextHeader {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  > .PickDatesIntro {
    text-align: center;
    letter-spacing: 0px;
    color: #232323;
    margin-bottom: 0.5em;
  }

  > .CampaignDurationDisclaimer {
    text-align: center;
    letter-spacing: 0px;
    color: #232323;
  }
}
