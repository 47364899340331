@import 'src/assets/styles/colors';

.Switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;

  > .SwitchInput {
    display: none;

    &:checked + .SwitchSlider {
      background-color: $adStatusPublished;
    }

    &:focus + .SwitchSlider {
      box-shadow: 0 0 1px $adStatusPublished;
    }

    &:checked + .SwitchSlider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }

  > .SwitchSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightgrey;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50px;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
}
