@import 'src/assets/styles/colors';
@import 'src/assets/styles/media';

.SelectedOptionContainer {
  text-align: left;
}

.SelectedOptionText {
  margin-bottom: 10px;
  margin-left: 20px;
  @media all and (max-width: $maxMobile) {
    margin-left: 0;
  }
}

.SelectedOption {
  display: inline-block;
  padding: 5px 5px 5px 10px;
  border: 1px solid $darkblue;
  border-radius: 24px;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 20px;
  @media all and (max-width: $maxMobile) {
    margin-left: 0;
    margin-right: 5px;
  }
  span {
    align-items: center;
    padding-left: 5px;

    img {
      padding-top: 2px;
    }
  }
}
