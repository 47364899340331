@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/media';

.UploadCodesContainer {
  display: flex;
  flex-direction: column;

  .UploadCodesContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media all and (max-width: $maxMobile) {
      flex-direction: column;
    }

    .DropdownContainer {
      width: 25%;
      @media all and (max-width: $maxMobile) {
        width: 100%;
      }
    }

    .ContinueContainer {
      flex: 1;
      margin-top: auto;
      margin-bottom: 0.9em;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .ButtonAddTable {
        width: 50%;
      }

      > .PersonalizedCodesText {
        margin-top: 1em;
        padding: 1em;
        background: var(--lightred);
      }
    }

    .CodeTypeContainer {
      margin-left: 2em;
      @media all and (max-width: $maxMobile) {
        margin-left: 0;
        margin-top: 1rem;
      }

      > .CodeTypeLabel {
        margin-bottom: 1rem;
        text-align: left;
        letter-spacing: 0px;
        color: #0c1026;
        height: 2rem;
      }
    }
  }

  .UploadCodesContentFile {
    display: flex;
    flex-direction: row;
    width: 100%;
    @media all and (max-width: $maxMobile) {
      flex-direction: column;
    }

    .DropdownContainer {
      width: 25%;
      @media all and (max-width: $maxMobile) {
        width: 100%;
      }
    }

    .CodeTypeContainer {
      margin-left: 2em;
      @media all and (max-width: $maxMobile) {
        margin-left: 0;
        margin-top: 1rem;
      }
    }
  }

  .ChangeFile {
    display: flex;
    margin-right: 2em;
    margin-left: 10px;
    margin-top: -4em;

    @media all and (max-width: $maxMobile) {
      margin: 0;
      flex-direction: column;
    }

    .UploadedFileContainer {
      display: flex;
      justify-content: space-between;
      margin-right: 2em;
      width: 100%;

      .Download {
        color: $darkblue;
        margin-left: 0.5em;
        font-weight: bold;
        text-decoration: underline;
        display: flex;
        align-items: center;
      }
    }

    .FileName {
      font-weight: bold;
    }


    p {
      text-align: left;
      color: $darkblue;
      max-width: 12.369791666666666vw;
      @include line-clamp(3);
      @media all and (max-width: $maxMobile) {
        max-width: unset;
      }
    }

    .UploadActions {
      display: flex;
      margin: auto 0 1.7em 2em;

      .Delete {
        color: $darkblue;
        margin-right: 0.5em;
        text-decoration: underline;
        cursor: pointer;
      }

      .Download {
        color: $darkblue;
        margin-left: 0.5em;
        text-decoration: underline;
      }
    }
  }

  .CenteredContentChange {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    justify-items: center;
    @media all and (max-width: $maxMobile) {
      flex-direction: column;
    }

    .Left {
      flex: 1;
      margin: auto 0;
      @media all and (max-width: $maxMobile) {
        margin: 1rem 0;
      }
    }

    &:after {
      flex: 1;
      content: '';
    }
  }

  .ArrowButton {
    width: 2.5em;
    height: 2.5em;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: auto;
    cursor: pointer;
  }
}

.UploadCodesModalContainer {
  min-width: 720px;
  margin-bottom: 1em;
  @media all and (max-width: $maxMobile) {
    min-width: unset;
  }

  .UploadSection {
    display: flex;
  }

  .Close {
    height: 30px;
    width: 30px;
    display: flex;
    margin-left: auto;
    cursor: pointer;
  }

  .HeaderContainer {
    margin-bottom: 1em;
    display: flex;
    justify-content: center;

    .Heading {
      line-height: 1;
      text-decoration: none;
      color: var(--darkblue);
      font-weight: bold;
    }
  }
}

.UploadContent {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2em;

  .DragAndDrop {
    width: 50%;
    min-height: 200px;
    margin-right: 1em;
    border: 1px solid #707070;
    border-radius: 20px;
    background: #ececec 0% 0% no-repeat padding-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: $maxMobile) {
      display: none;
    }

    .Upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .DragAndDropIcon {
      height: 32px;
      width: 32px;
    }
  }

  .FileUpload {
    width: 50%;

    .UploadCodesButtonContainer {
      line-height: 1;
      border-radius: 2rem;
      border: 2px solid var(--darkgrey);
      text-decoration: none;
      color: var(--darkblue);
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-weight: normal;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      .UploadContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .UploadImage {
          height: 30px;
          width: 30px;
          margin-left: 10px;
        }
      }
    }
  }
}

.Error {
  margin-top: 1em;
  margin-bottom: 4em;

  .ErrorIcon {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 30px;
    width: 30px;
    @include svg-icon(
      '../../../assets/images/icon-close.svg',
      30px,
      30px,
      $lightred
    );
  }

  span {
    font-weight: bold;
  }
}

.DownloadLink {
  color: $darkblue;
  margin-top: 3em;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Yettel';
  font-size: 18px;
}

.FileName {
  color: $darkblue;
}

.ProgressBarContainer {
  margin: auto;
  width: 80%;

  .ProgressBarLabel {
    display: flex;
    justify-content: space-between;
  }
}

.EnterCodesContainer {
  display: flex;
  flex-direction: row;
  width: 50%;
  @media all and (max-width: $maxMobile) {
    flex-direction: column;
    width: 100%;
  }

  .DropdownContainer {
    width: 50%;
    @media all and (max-width: $maxMobile) {
      width: 100%;
    }
  }

  .InputField {
    width: 50%;
    margin-left: 10px;
    @media all and (max-width: $maxMobile) {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.Required {
  color: var(--requiredstar);
}
