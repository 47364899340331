@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  flex-direction: row;
  width: fit-content;
  height: fit-content;
  > * {
    margin-left: 6px;
    margin-right: 6px;
  }
  > .Prev10 {
    cursor: pointer;
    @include svg-icon(
      '../../../assets/icons/double-left.svg',
      1.6em,
      1em,
      var(--darkblue)
    );
    margin-left: 0;
  }
  > .Prev {
    cursor: pointer;
    @include svg-icon(
      '../../../assets/icons/arrow-left.svg',
      1.6em,
      1em,
      var(--darkblue)
    );
  }
  > .Main {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  > .Next {
    cursor: pointer;
    @include svg-icon(
      '../../../assets/icons/arrow-right.svg',
      1.6em,
      1em,
      var(--darkblue)
    );
  }
  > .Next10 {
    cursor: pointer;
    @include svg-icon(
      '../../../assets/icons/double-right.svg',
      1.6em,
      1em,
      var(--darkblue)
    );
    margin-right: 0;
  }
}
