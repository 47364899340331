@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Btn {
  position: relative;
  margin: 0 auto;
  background: none;
  font-size: 1.125em;
  color: var(--darkblue);
  line-height: 1;
  background-color: var(--white);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  border: var(--darkblue) 2px solid;
  cursor: pointer;
}

.YellowBtn {
  position: relative;
  margin: 0 auto;
  background: none;
  font-size: 1.125em;
  color: var(--darkblue);
  line-height: 1;
  background-color: $yellowLabel;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
}

.BtnDisabled {
  @extend .Btn;
  background-color: var(--lightgrey);
  cursor: not-allowed;
}
