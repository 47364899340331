@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.UploadCodesContainer {
  display: flex;
  flex-direction: column;

  .UploadCodesContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 554px;

    .DropdownContainer {
      width: 25%;
    }

    .ContinueContainer {
      flex: 1;
      margin-top: auto;
      margin-bottom: 0.9em;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .ButtonAddTable {
        width: 50%;
      }

      > .PersonalizedCodesText {
        margin-top: 1em;
        padding: 1em;
        background: var(--lightred);
      }
    }

    .CodeTypeContainer {
      margin-left: 2em;

      > .CodeTypeLabel {
        margin-bottom: 1rem;
        text-align: left;
        letter-spacing: 0px;
        color: #0c1026;
        height: 2rem;
      }
    }
  }

  .UploadCodesContentFile {
    display: flex;
    flex-direction: row;
    width: 100%;

    .DropdownContainer {
      width: 25%;
    }

    .CodeTypeContainer {
      margin-left: 2em;
    }
  }

  .ChangeFile {
    display: flex;
    margin-right: 2em;
    margin-left: 10px;
    margin-top: -4em;

    .UploadedFileContainer {
      display: flex;
      justify-content: space-between;
      margin-right: 2em;
      width: 100%;

      .Download {
        color: $darkblue;
        margin-left: 0.5em;
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .FileName {
      font-weight: bold;
    }

    p {
      text-align: left;
      color: $darkblue;
      max-width: 12.369791666666666vw;
      @include line-clamp(3);
    }

    .UploadActions {
      display: flex;
      margin: auto 0 1.7em 2em;

      .Delete {
        color: $darkblue;
        margin-right: 0.5em;
        text-decoration: underline;
        cursor: pointer;
      }

      .Download {
        color: $darkblue;
        margin-left: 0.5em;
        text-decoration: underline;
      }
    }
  }

  .CenteredContentChange {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    justify-items: center;

    .Left {
      flex: 1;
      margin: auto 0;
    }

    &:after {
      flex: 1;
      content: '';
    }
  }

  .ArrowButton {
    width: 2.5em;
    height: 2.5em;
    margin-left: 10px;
    margin-bottom: 20px;
    margin-top: auto;
    cursor: pointer;
  }
}

.UploadCodesModalContainer {
  min-width: 567px;
  margin-bottom: 1em;

  .UploadSection {
    display: flex;
  }

  .Close {
    height: 30px;
    width: 30px;
    display: flex;
    margin-left: auto;
    cursor: pointer;
  }

  .HeaderContainer {
    margin-bottom: 1em;
    display: flex;
    justify-content: center;

    .Heading {
      line-height: 1;
      text-decoration: none;
      color: var(--darkblue);
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.UploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  height: 300px;
  justify-content: space-between;
  & :hover {
    cursor: pointer;
  }
  .FileFormat {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
    }
    .DocumentIcon {
      @extend .DragAndDropIcon;
      margin-right: 4px;
    }
  }
  .DragAndDrop {
    width: 368px;
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 200px;
    margin-right: 1em;
    border-radius: 20px;
    background: #ececec 0% 0% no-repeat padding-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px dashed #b3bdc6;

    .Upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .UploadText {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    .DragAndDropIcon {
      height: 32px;
      width: 32px;
    }
  }

  .FileUpload {
    width: 50%;
    margin-bottom: 30px;

    .UploadCodesButtonContainer {
      line-height: 1;
      border-radius: 2rem;
      border: 2px solid var(--darkgrey);
      text-decoration: none;
      color: var(--darkblue);
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-weight: normal;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      width: 168px;

      .UploadContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .UploadImage {
          height: 30px;
          width: 30px;
          margin-left: 10px;
        }
      }
    }
  }
}

.Error {
  margin-top: 1em;
  margin-bottom: 4em;

  .ErrorIcon {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 30px;
    width: 30px;
    @include svg-icon(
      '../../../assets/images/icon-close.svg',
      30px,
      30px,
      $lightred
    );
  }

  span {
    font-weight: bold;
  }
}

.DownloadLink {
  color: $darkblue;
  margin-top: 3em;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Yettel';
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  .DocumentIcon {
    @extend .DragAndDropIcon;
    margin: 3px;
  }
}

.FileName {
  color: $darkblue;
}

.ProgressBarContainer {
  margin: auto;
  width: 80%;

  .ProgressBarLabel {
    display: flex;
    justify-content: space-between;
  }
}

.EnterCodesContainer {
  display: flex;
  flex-direction: row;
  width: 50%;

  .DropdownContainer {
    width: 50%;
  }

  .InputField {
    width: 50%;
    margin-left: 10px;
  }
}

.Required {
  color: var(--requiredstar);
}
