.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
