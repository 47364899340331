.OfferDurationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .HelperText {
    padding: 0;
    color: #99A7B3;
    font-size: 14px;
    font-family: Yettel-Light;
  }
}
