@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.75em;
  }
  .PaginationOuterContainer {
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
  }
}

.NoElementsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  justify-items: center;
  > p {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
    color: var(--darkblue);
  }
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 1em;
  font-family: $fontPrimary;
  cursor: pointer;
}
