@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.FadedLabel{
  font-size: 14px;
  color: #99A7B3;
  font-family: Yettel-Light;
}

.Required {
  color: var(--requiredstar);
}

.SelectSearchLabel {
  text-align: left;
  font-family: "Yettel";
}

.CustomDropdownLabels {
  display: flex;
  justify-content: space-between;
}

.DropdownOptionGroupsOptionsList {
  border: 1px solid lightgrey;
  height: 400px;
  resize: both;
  overflow-y: scroll;
}


.SelectArrows {
  z-index: 2;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 0;

  .SelectArrowsInnerContainer {
    float: right;
    margin-right: 0.5em;

    > .UpArrow {
      @include svg-icon('../../icons/dropdown-up.svg', 6.5px, 5px, #373a3c);
    }

    > .DownArrow {
      @include svg-icon('../../icons/dropdown.svg', 6.5px, 5px, #373a3c);
    }
  }
}

