@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
}

.TableWrapper {
  width: 80%;
  margin: 0 auto;
}

.TableTitle {
  margin-bottom: 1rem;
  text-align: left;
}

.TableContainer {
  background-color: #f2f2f2;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  > .Section {
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 3px 6px #00000029;
    padding: 1.5em 1.5em 2em;
    > .TableContent {
      display: flex;
      width: 100%;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      > table {
        display: flex;
        flex-direction: column;
        width: 100%;
        table-layout: fixed;
      }
      .ActionCell {
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;
      }
    }
    .BtnWrapper {
      margin-top: 2rem;
    }
    .ActionBtn {
      font-family: 'Yettel';
      font-weight: normal !important;
      color: $darkblue;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

.HeaderRow {
  background-color: var(--darkblue);
}

.Right {
  display: flex !important;
  flex-direction: row !important;
  align-content: flex-end !important;
  justify-content: flex-end !important;
  margin-right: 3em !important;
}

.Container tr {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  border-bottom: 0.5px solid #a4a4a4;
  > th {
    display: flex;
    flex-direction: row;
    margin: auto;
    text-align: left;
    color: white;
    width: calc(100% / 2);
    font-family: 'Yettel';
    font-weight: normal !important;
  }
  > td {
    display: flex;
    flex-direction: row;
    margin: auto;
    text-align: left;
    width: calc(100% / 2);
    color: var(--darkblue);
    font-family: 'Yettel';
  }
}


.AddAccountContainer {
  margin-top: 1em;
  > .Plus {
    margin-right: 0.25em;
  }
}

.PaginationContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
  justify-items: center;
  align-items: center;
}

.Edit {
  height: 32px !important;
  cursor: pointer;
  margin-right: 0.2em;
}

.Delete {
  @extend .Edit;
  margin-left: 1.3em;
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 1em;
  font-family: $fontPrimary;
  cursor: pointer;
}

.SortIcon {
  margin-left: 0.25em;
  margin-top: 0.15em;
  cursor: pointer;
}

.BreakWord {
  line-break: anywhere;
  display: flex;
  width: 80%;
}