@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.InfoSection {
  position: relative;
  margin-top: 2rem;
  text-align: center;
  .Store {
    @include justify-between;
    @include flex-center-vertical;
    position: relative;
    margin: 0 0 0.5rem;
    text-align: left;
    .StoreName {
      margin-bottom: 0;
    }
  }
  .Title {
    position: relative;
    flex-basis: 100%;
    margin: 0 0 0.5rem;
    font-size: 18px;
    font-family: $fontBold;
    text-align: left;
    line-height: unset;
  }
  .WindPayIcon {
    width: 30px;
  }
}

.CouponBtnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  justify-items: center;
  > * {
    margin-left: 25%;
    margin-right: 25%;
  }
}

.PriceContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1em;
  > .OldPrice {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
    justify-items: flex-end;
    align-items: flex-end;
    > * {
      color: var(--greynotselected);
      text-decoration: line-through;
      text-align: right;
      font-family: $fontRegular;
    }
  }
  > .RegularPrice {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $lightgrey;
    border-top: 1px solid $lightgrey;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    > .RegularPriceRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      > .PriceText {
        display: flex;
        flex-direction: row;
        width: 50%;
        align-content: flex-start;
        justify-content: flex-start;
        font-family: $fontRegular;
      }
      > .PriceAmount {
        display: flex;
        flex-direction: row;
        width: 50%;
        align-content: flex-end;
        justify-content: flex-end;
        font-family: $fontRegular;
      }
    }
  }
}

.ExpirationContainer {
  margin-bottom: 0.5em;
}
