.container {
  width: 100%;
}

.container .react-tabs__tab-list * {
  margin: 0 0em !important;
  margin-right: 2em !important;
  padding-left: 0;
}

.container .react-tabs__tab-list {
  border-bottom: 1px solid #b8b8b8;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
}
