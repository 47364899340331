@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: transparent;

  > .PageTitleContainer {
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    text-align: center;
    background-color: white;

    > .PageTitle {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      margin: 2rem 0 0;
      color: $darkblue;
      opacity: 1;
    }
  }

  > .CenteredTabsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1rem;
    background-color: white;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
}

.TabPanelContentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
}
