.Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  //margin-left: 2em;
  > .BasicContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 2em;
    > .BasicRow {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5em;
      width: 100%;
      > .BasicElement {
        padding-bottom: 0.2em;
        border-bottom: 1px solid #b8b8b8;
        margin-right: 0.5em;
        display: flex;
        flex-direction: row;
        text-align: left;
        width: 85%;
      }
    }
  }
}

.Right {
  min-width: 15%;
  max-width: fit-content;
  align-content: flex-end;
  justify-content: flex-end;
  > * {
    margin-right: 0.5em;
  }
}

.NumberOfUsedCoupons {
  margin-top: 5em;
  border-top: 1px solid #b8b8b8;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
  > .CouponsAvailableContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1em;
    > .MaxNumber {
      display: flex;
      flex-direction: row;
      width: 70%;
      text-align: left;
    }
    > .CouponCount {
      display: flex;
      flex-direction: column;
      width: 30%;
      padding: 1em;
      margin-top: -1em;
    }
  }
  > .HeaderText {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 1em;
  }
  .Input {
    display: flex;
    flex-direction: row;
    width: 100%;
    > .InputFieldContainer {
      display: flex;
      flex-direction: row;
      width: 50%;
    }
    > .InputBtnContainer {
      display: flex;
      flex-direction: row;
      width: 40%;
      padding-left: 1em;
    }
  }
  > .UsedCodesTable {
    display: flex;
    flex-direction: column;
    width: 100%;
    > .BaseRow {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      > .Key {
        display: flex;
        flex-direction: row;
        width: 50%;
        align-content: flex-start;
        justify-content: flex-start;
      }
      > .Value {
        display: flex;
        flex-direction: row;
        width: 50%;
        align-content: flex-end;
        justify-content: flex-end;
        margin-right: 0.25em;
      }
    }
    > .UsedCodeRow {
      @extend .BaseRow;
      border-bottom: 1px solid #b8b8b8;
    }
    > .TotalRow {
      @extend .BaseRow;
    }
    > .RemainingRow {
      @extend .BaseRow;
      background-color: #f6f6f6;
    }
  }
}
