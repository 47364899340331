@import "src/assets/styles/colors";

.MobileHeaderProfileModal {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: $footerBackground;
  bottom: 0;
  z-index: 99999;
}

.MobileHeaderProfileBack {
  background: #D7F0F9;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.goBack {
  margin-left: 10px;
  font-size: 16px;
  font-family: Yettel-Bold;
  cursor: pointer;
}

.goBackText {
  text-decoration: underline;
}

.MobileHeaderProfileContent {
  width: 90%;
  margin: 0 auto;
}

.MobileHeaderUserInfo, .MobileHeaderMenuCompany {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #aaa;
  .MobileHeaderUserInfoName {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .MobileHeaderUserInfoNameEmail {
    font-size: 15px;
  }

  .MobileHeaderMenuCompanyName {
    font-family: Yettel-Bold;
    font-size: 15px;
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.MobileHeaderMenuCompany {
  margin-top: 15px;
  padding-bottom: 15px;
}

.MobileHeaderUserInfo {
  margin-top: 30px;
  padding-bottom: 15px;
}

.MobileHeaderMenuLogout {
  margin-top: 25px;
}

.MobileHeaderChangeCompany, .MobileHeaderMenuLogoutText {
  text-decoration: underline;
  font-size: 18px;
}


