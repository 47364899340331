@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.Header {
  font-weight: bold;
}

.Description {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 2em;
  font-family: $fontLight;
  font-size: 16px;

  span {
    font-size: 16px;
  }
}

.Table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;

  th {
    text-align: left;
  }

  td {
    text-align: left;
    padding: .5em;
  }

  td:nth-of-type(1) {
    width: 70%;
  }

  tr:nth-child(even) {
    background-color: $filterbg;
  }
}