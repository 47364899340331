.filterWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.filterIcon {
  text-align: left;
  margin: 0;
  cursor: pointer;
}

.resetButton div {
  padding: 10px 15px;
  font-size: 14px;
  font-family: Yettel-Bold;
}

.filterHeader {
  display: flex;
  gap: 0.5em;
  padding: 10px 10px 0 10px;
  align-items: center;
}
.wideInput {
  width: unset !important;
  max-width: unset !important;
  z-index: 1;
}
