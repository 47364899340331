@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.MessageContainer {
  display: flex;
  flex-direction: row;
  width: fit-content;

  .Checkmark {
    @include svg-icon(
                    '../../../assets/images/icon-check.svg',
                    25px,
                    25px,
                    green
    );
  }

  .MessageText {
    margin-left: 0.5em;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    opacity: 1;
  }
}
