.LawElements {
  display: flex;
  flex-direction: column;
  justify-content: left;
  justify-items: left;
  align-items: left;
  align-content: left;

  .LawElement {
    display: flex;
    margin-top: 2em;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    align-items: left;
    align-content: left;
    width: fit-content;
    height: fit-content;
    div {
      text-align: left;
    }
    p {
      text-align: left;
    }
    ul {
      padding: 1em 2em;
      text-align: left;
      li {
        list-style-type: disc;
      }
    }
  }
}

