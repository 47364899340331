@import 'src/assets/styles/variables';

.Container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  > .ColumnsContainer {
    display: flex;
    flex-direction: row;
    width: 80%;
    > .Column {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 70vh;
      max-height: fit-content;
      margin-left: 1em;
      margin-right: 1em;
      > .FieldsContainer {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        > .InputFieldContainer {
          display: flex;
          width: 100%;
          margin-bottom: 0.5em;
        }
        > .SmallInputFieldContainer {
          @extend .InputFieldContainer;
          width: 188px;
        }
        > .CheckboxInputFieldContainer {
          @extend .InputFieldContainer;
          flex-direction: column;
          align-items: flex-start;
        }
        > .NoteContainer {
          @extend .InputFieldContainer;
          text-align: justify;
          letter-spacing: 0px;
          margin-bottom: 2em;
          font-family: $fontLight;
        }
      }
      > .UploadBtnContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: flex-start;
        .CodeTypeLabel {
          margin-bottom: 10px;
        }
        .ContractContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-top: 1px solid #67797a;
          border-bottom: 1px solid #67797a;
          height: 47px;
          margin-bottom: 30px;
          * {
            margin: 0;
          }
          p {
            font-size: 14px;
          }
          .DownloadDeleteContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            .DeleteIcon {
              margin-left: 20px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  > .SaveBtnContainer {
    margin-top: 0em;
    display: flex;
    width: 100%;
    justify-items: center;
    align-items: center;
  }
}
