@import 'src/assets/styles/media';

.Smartphone {
  position: relative;
  width: 20vw;
  height: 43vw;
  margin: auto;
  border: 1em #152330 solid;
  border-top-width: 2.55em;
  border-bottom-width: 2.55em;
  border-radius: 2.25em;
  @media all and (max-width: $maxMobile) {
    width: 100%;
    margin: 0;
    height: 100vh;
  }
  > .Content {
    width: 100%;
    height: 100%;
    font-size: 0.6rem;
    background: white;
    overflow: auto;
    -ms-overflow-style: none; /*  hide scrollbar: Internet Explorer 10+ */
    scrollbar-width: none; /* hide scrollbar: Firefox */
  }
  > .Content::-webkit-scrollbar {
    display: none; /* hide scrollbar: Safari and Chrome */
  }
}
