@import 'src/assets/styles/colors';

.offerPageSlider {
  .carousel-root {
    .carousel-slider {
      position: unset;
      width: 100% !important;
    }
    .control-dots {
      position: absolute;
      bottom: -1.5rem;
      margin: 0;
      line-height: 1;
      .dot {
        width: 0.8rem;
        height: 0.8rem;
        box-shadow: none;
        background-color: $offerDetailSliderInactiveDot;
        &.selected {
          background-color: $darkblue;
          border: 1px solid $green;
        }
      }
    }
  }
}
