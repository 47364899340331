@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

/* TOP OFFER */
$topOfferImageHeight: 150px;
$topOfferContentHeight: 150px;
$topOfferFullHeight: 300px;
$topOfferFullWidth: 340px;

/* FEATURED OFFER */
$featuredOfferImageHeight: 80px;
$featuredOfferContentHeight: 163px;
$featuredOfferFullHeight: 230px;

/* STANDARD OFFER */
$standardOfferImageHeight: 50px;
$standardOfferImageWidth: 100px;
$standardOfferFullHeight: 100px;

.AdTypeContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media all and (max-width: $maxMobile) {
    flex-direction: column;
  }
}

.PartnerInfo {
  font-family: $fontPrimary;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5rem;
}

li {
  letter-spacing: 0 !important;
  font-family: $fontPrimary !important;
  font-size: 16px !important;
  color: var(--darkblue) !important;
}

/* top add */
.TopAdContainer {
  flex-basis: 31%;
  text-align: left;
  margin-right: 3.3333%;
  width: 100%;
  max-width: 480px;
  @media all and (max-width: $maxMobile) {
    margin-bottom: 100px;
    margin-top: -55px;
  }
  .TopAd {
    position: relative;
    display: block;
    top: 0;
    background-color: $bgmain;
    box-shadow: $singleOfferShadow;
    border: 1px solid $lightgrey;
    border-radius: 12px;
    text-decoration: none;
    color: $darkblue;
    margin-bottom: 16px;
    cursor: pointer;
    overflow: hidden;

    .TopSection {
      position: relative;
      width: 100%;
      height: $topOfferImageHeight;
      border-radius: 13px 13px 0 0;
      text-align: center;

      .UploadedImage {
        width: 100%;
        height: 100%;
      }
    }

    .Discount {
      position: absolute;
      top: 0.75rem;
      left: -1px;
      z-index: 1;
      background-color: $green;
      border-radius: 0 4px 4px 0;
      font-size: 1rem;
      line-height: 1;
      padding: 0.5rem 13px 0.5rem;
      font-family: $fontMedium;
    }

    .LabelText {
      position: absolute;
      top: 3.5rem;
      left: -1px;
      z-index: 1;
      border-radius: 0 4px 4px 0;
      font-size: 1rem;
      line-height: 1;
      padding: 0.5rem 13px 0.5rem;
      font-family: $fontMedium;
    }

    .YettelPayRibbon {
      top: 3rem;
    }

    .Content {
      position: relative;
      height: $topOfferContentHeight;
      margin-top: calc(
        #{-$topOfferImageHeight} + #{-$topOfferContentHeight} + #{$topOfferFullHeight}
      );
      width: 100%;
      padding: 8px 1rem 6px;
      background-color: $white;
      border-radius: 8px 8px 0 0;

      .Title {
        position: relative;
        font-size: 16px;
        font-family: $fontMedium;
        margin: 0 0 0.1rem;
        text-align: left;
        line-height: 1.1;
        height: calc(3.3rem);
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .RibbonPrice {
      top: -1.9rem;
    }

    .Store {
      position: relative;
      font-size: 14px;
      line-height: 1;
      margin: 0 0 4px;
      text-align: left;
      height: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .InfoTextWrapper {
      @include flex-center-vertical;
    }

    .BottomSection {
      @include justify-between;
    }
  }
  .UsedMaxTenTopAds {
    border-radius: 8px;
    background-color: #ffe9d3;
    width: 568px;
    height: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 44px;
    img {
      height: 31px;
    }
    p {
      margin: 0;
      font-size: 15px;
    }
  }
}

.UsedMaxFiveTopAds {
  margin-top: 20px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 136px;
  width: 731px;
  background-color: #dcf1f9;
  padding: 20px;
  img {
    height: 31px;
  }
  .TextContainer {
    .Text {
      margin: 0;
    }
    .TextInfo {
      font-size: 15px;
      font-family: 'Yettel-Light';
      margin: 0;
    }
  }
}
.AdditionActivated {
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  .Icon {
    height: 37px;
  }
  p {
    margin: 0;
    font-size: 18px;
  }
}

.TopAdsCounterBox {
  margin-top: 1rem;
  border-radius: 8px;
  padding: 23px 32px 23px 15px;
  width: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcfad9;
  .Icon {
    height: 37px;
  }
  p {
    margin: 0;
  }
}
/* wind pay icon */

.WindPayIcon {
  width: 30px;
}

/* info section */

.Info {
  @include flex-center-vertical;
  position: relative;
  top: 0;
  width: 100%;
  text-align: left;

  .ExpirationIcon {
    position: relative;
    width: 1rem;
    height: auto;
    margin-right: 0.33rem;
  }

  .ExpirationText {
    position: relative;
    color: $lightred;
    font-size: 0.66rem;
    margin-right: 0.33rem;
    line-height: unset;
  }

  .Distance {
    @include flex-center-vertical;
    margin-left: 0.33rem;
  }

  .DistanceText {
    font-size: 0.75rem;
    align-items: center;
  }

  .DistanceIcon {
    position: relative;
    height: 1rem;
    width: auto;
    margin-right: 0.33rem;
  }

  .LocationContainer {
    display: flex;
  }

  .LocationIcon {
    position: relative;
    height: 1rem;
    width: auto;
    margin-right: 0.33rem;
  }
}

.DescriptionSection {
  margin-left: 0.5em;
  flex-basis: 50%;

  .Content {
    li {
      line-height: 1.4;
      display: flex;
      flex-direction: row;

      &:before {
        content: '- ';
        margin-right: 0.5rem;
      }
    }
  }
}

/* featured ad */

.FeaturedAdContainer {
  text-align: left;
  margin-right: 3.3333%;
  max-width: 480px;
  display: flex;
  height: fit-content;
  flex-basis: 31%;

  .FeaturedAd {
    position: relative;
    display: block;
    top: 0;
    //width: 100%;
    //flex-basis: 50%;
    box-shadow: $singleOfferShadow;
    border: 1px solid $lightgrey;
    border-radius: 12px;
    background-color: $white;
    text-decoration: none;
    color: $darkblue;
    margin-bottom: 16px;
    overflow: hidden;
    cursor: pointer;

    .TopSection {
      pointer-events: none;
      text-align: center;
      position: relative;
      width: 100%;
      height: $featuredOfferImageHeight;
      background-color: $white;

      .UploadedImage {
        width: 100%;
        height: 100%;
      }
    }

    .Discount {
      position: absolute;
      top: 0.75rem;
      background-color: $green;
      border-radius: 0 4px 4px 0;
      font-size: 1.125rem;
      line-height: 1;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
    }

    .RibbonPrice {
      bottom: -0.5rem;
    }

    .Content {
      @include flex-column;
      @include justify-between;
      height: $featuredOfferContentHeight;
      margin-top: calc(
        #{-$featuredOfferImageHeight} + #{-$featuredOfferContentHeight} + #{$featuredOfferFullHeight}
      );
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 22px 0.5rem 6px;
      background-color: $white;
      border-radius: 12px 12px 0 0;
      box-shadow: 0 6px 10px #e7eaf0;

      .Title {
        position: relative;
        font-size: 16px;
        font-family: $fontMedium;
        margin: 0 0 6px;
        text-align: left;
        line-height: 1.1;
        height: calc(4.4rem + 6px);
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .Store {
        @include justify-between;
        position: relative;
        margin: 0 0 4px;
        text-align: left;

        .StoreName {
          font-size: 14px;
          line-height: 1;
          height: calc(28px + 1px);
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
          color: $storeName;
        }
      }
    }

    .Store {
      position: relative;
      margin: 0 0 0.25rem;
      text-align: left;
    }

    .BottomSection {
      @include justify-between;
      display: flex;
      flex-direction: column;
    }
  }
}

/* standard ad */

.StandardAdContainer {
  flex-basis: 31%;
  text-align: left;
  margin-right: 3.3333%;
  max-width: 480px;
  //max-height: 19em;
  @media all and (max-width: $maxMobile) {
    flex-basis: 46%;
    margin-right: 0;
  }
  .StandardAd {
    position: relative;
    width: 100%;
    max-width: 480px;
    padding: 10px 8px;
    background-color: $white;
    box-shadow: $singleOfferShadow;
    border: 1px solid $lightgrey;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    color: $darkblue;
    margin-bottom: 16px;
    cursor: pointer;
    overflow: hidden;
    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    .TopSection {
      width: 100%;
      margin-bottom: 4px;

      .Store {
        font-size: 14px;
        line-height: 1;
        color: $storeName;
        width: 100%;
        margin-bottom: 0;
        height: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .PhotoWrapper {
      @include flex-alignitems-flex-start;
      pointer-events: none;
      position: relative;
      width: $standardOfferImageWidth;
      height: $standardOfferImageHeight;
      border-radius: 4px;
      overflow: hidden;

      .UploadedImage {
        width: $standardOfferImageWidth;
        height: $standardOfferImageHeight;
      }
    }

    .Content {
      position: relative;
      width: 100%;
      display: flex;

      .TextWrapper {
        width: calc(100% - #{$standardOfferImageWidth});
        padding-left: 8px;
      }

      .Title {
        position: relative;
        margin: 0;
        font-size: 16px;
        font-family: $fontMedium;
        text-align: left;
        line-height: 1.1;
        height: 2.3rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .Info {
        @include flex-center-vertical;
        @include flex-end;
        width: 100%;
        min-height: 10px;

        .OriginalPrice {
          display: inline-block;
          color: $border;
          font-family: $fontMedium;
          font-size: 14px;
          text-decoration: line-through;
          margin: 0 0.5rem 0;
          white-space: nowrap;
          line-height: 1.15;
        }

        .Price {
          .Amount {
            display: inline-block;
            font-family: $fontMedium;
            font-size: 18px;
            color: $storeName;
            white-space: nowrap;
            margin: 0;
            line-height: 1.15;
          }
        }
      }
    }
  }
}

.RibbonPrice {
  @include flex-column;
  text-align: right;
  position: absolute;
  z-index: 3;
  right: 0;
  border-radius: 4px 0 0 4px;
  background-color: $darkblue;
  color: $white;
  font-size: 1.125rem;
  line-height: 1;
  padding: 4px 10px 6px 14px;

  .RegularPrice {
    color: $tabsBottomBorder;
    font-family: $fontMedium;
    font-size: 0.8rem;
    text-decoration: line-through;
    margin-bottom: 6px;
    white-space: nowrap;
  }

  .Amount,
  .Currency {
    color: $white;
  }
}

.RegularPrice {
  color: var(--greynotselected);
  font-size: 0.7rem;
  text-decoration: line-through;
  text-align: right;
}
