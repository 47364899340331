.dateWrapper {
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 0%;
  left: 0%;
  box-shadow: 1px 1px 25px var(--lightgrey);
  border-radius: 8px;
}

.filterHeader {
  display: flex;
  gap: 0.5em;
  padding: 10px 10px 0 10px;
  align-items: center;
}

.filterIcon {
  margin-right: 0;
}

.resetButton {
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
  font-family: Yettel-Bold;

  div {
    font-size: 16px;
    padding: 16px 28px;
  }
}
