@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

/* FEATURED OFFERS */

.FeaturedOffer,
.ValidCoupon {
  position: relative;
  width: 48%;
  box-shadow: 0 6px 10px #e7eaf0;
  border: 1px solid $lightgrey;
  border-radius: 13px;
  background-color: $white;
  text-decoration: none;
  color: $darkblue;
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;
  .YettelPayRibbon {
    top: 0.5rem;
  }
  .TopSection {
    position: relative;
    width: 100%;
    height: 8rem;
    text-align: center;
    background-color: $white;
  }
  .Photo {
    width: 100%;
    height: calc(100% + 8px);
  }
  .Price {
    @include flex-column;
    text-align: right;
    position: absolute;
    z-index: 3;
    bottom: -0.5rem;
    right: 0;
    border-radius: 4px 0 0 4px;
    background-color: $darkblue;
    color: $white;
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    .OriginalPrice {
      color: $tabsBottomBorder;
      font-family: $fontMedium;
      font-size: 0.8rem;
      text-decoration: line-through;
      margin-bottom: 0.15rem;
      white-space: nowrap;
    }
    .Amount,
    .Currency {
      color: $white;
    }
  }

  .Content {
    @include flex-column;
    @include justify-between;
    height: calc(100% - 8rem);
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 1rem 0.5rem 0.5rem;
    background-color: $white;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 6px 10px #e7eaf0;
    .Title {
      position: relative;
      font-size: 1rem;
      margin: 0 0 0.33rem;
      text-align: left;
      line-height: unset;
      height: 6rem;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .Store {
      @include justify-between;
      position: relative;
      margin: 0 0 0.25rem;
      text-align: left;

      .StoreName {
        font-size: 0.9rem;
        line-height: unset;
        margin-bottom: 0;
        color: $storeName;
      }
    }
  }
}

.ExpiredCoupon {
  .Content {
    .Store {
      text-align: left;
    }
    .Info {
      @include justify-between;
    }
  }
}

/* YETTEL PAY RIBBON */

.YettelPayRibbon {
  position: absolute;
  left: -1px;
  z-index: 1;
}
