$white: #fff;
$green: #b4ff00;
$darkblue: #002340;
$background: #f2f2f2;
$approvalbg: #fcfff3;
$bgfeatured: #f3faff;
$lightblue: #80b0d6;
$blue: #005aa4;
$mediumblue: #80b0d6;
$lightgrey: #c8c8c8;
$darkgrey: #303030;
$filterbg: #f6f6f6;
$bgmain: #fefefe;
$lightred: #e65656;
$linkactive: #76ff05;
$adStatusPublished: #61ce0e;
$adStatusPublishedSoon: #a6e675;
$adStatusSubmitted: #ffdd00;
$adStatusRejected: #ff0000;
$adStatusStopped: #ffb433;
$adStatusExpired: #cfdcdc;
$discountbg: #ebf6ff;
$lightorange: #d67e18;
$adtypebackground: #fefcf6;
$locationblue: #f2fcff;
$buttonGrey: #e5e9ec;
$fileUploadBackgroundGrey: #f9f9f9;
$pendingStatus: #ff5728;
$activeStatus: #54bf78;
$inactiveStatus: #80919f;
$rejectedStatus: #ff5728;
$loaderBackground: rgba(255, 255, 255, 0.5);
$validOffer: #54bf78;
$adStatusPublishedSoon: #a6e675;
$tabsBottomBorder: #80919f;
$storeName: #2e2e2e;
$border: #aeaeae;
$standardOfferPrice: #0666aa;
$red: #ff1e1e;
$requiredStar: #f8a6a6;
$offerDetailSliderInactiveDot: #ccd3d9;
$footerBackground: #f2f4f5;
$footerMenuInactive: #586a74;
$yellowLabel: #fff6cf;
$blueLabel: #c4dfe9;
$pinkLabel: #ffe9d3;
$bottomBorder: #b8b8b8;

:root {
  --background: #f8f8f8;
  --darkblue: #002340;
  --white: #ffffff;
  --darkgrey: #292929;
  --blue: #005aa4;
  --mediumblue: #80b0d6;
  --lightblue: #eff8ff;
  --publishedsoonstatus: #a6e675;
  --pink: #ffe1fc;
  --lightgrey: #c9c9c9;
  --greynotselected: #80919f;
  --lightgreen: #f9ffec;
  --bgblue: #eaf4f8;
  --lightyellow: #fffce8;
  --green: #b4ff00;
  --darkgreen: #658b0b;
  --darkred: #8b0b0b;
  --lightred: #fff7ec;
  --orange: #e65656;
  --pendingstatus: #ffdd00;
  --codespentstatus: #6dbbff;
  --activestatus: #61ce0e;
  --publishedsoonstatus: #a6e675;
  --inactivestatus: #cfdcdc;
  --rejectedstatus: #ff0000;
  --publishedsoonupdate: #ffb300;
  --validoffer: #54bf78;
  --tabsbottomborder: #80919f;
  --storename: #2e2e2e;
  --border: #aeaeae;
  --standardofferprice: #0666aa;
  --requiredstar: #f8a6a6;
}
