@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
.FormHeaderText {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: var(--pink);
  color: var(--darkblue);
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  text-decoration: none;
  margin: 15px 10px 20px;
  width: calc(100% - 1rem);

  h3 {
    color: var(--darkblue);
    margin: auto 0;
    text-align: left;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    user-select: none;
  }
}

.ChildrenContainer {
  display: contents;
  transition: all 0.3s smooth;
}

.ChildrenContainerHidden {
  @extend .ChildrenContainer;
  transition: all 0.3s smooth;
  display: none !important;
}
.FormHeaderCheckbox {
  width: 100%;
}
