@import 'src/assets/styles/media';
@import 'src/assets/styles/variables';

.LoginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  .Login {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: fit-content;
    @media all and (max-width: $maxMobile) {
      width: 80%;
      text-align: left;
      font-size: 0.75em;
      h1 {
        margin: 30px 0 15px;
        font: normal normal bold 24px/29px Yettel;
        letter-spacing: 0px;
        color: $colorPrimary;
        padding-right: 5%;
      }
    }
  }
}

.OuterInputFieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  @media all and (max-width: $maxMobile) {
    align-items: center;
    margin-top: 2em;
    &:last-of-type {
      margin-top: 2.5em;
    }
    label {
      font-size: 16px !important;
    }
    a {
      font: normal normal bold 18px/22px Yettel;
    }
  }
}

.LoginBtnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
  @media all and (max-width: $maxMobile) {
    margin-top: 3em;
  }
}

.CaptchaContainer {
  display: flex;
  justify-content: center;
}
