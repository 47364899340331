.RadioButtonContainer {
  position: relative;
  flex-basis: 31%;
  text-align: left;
  margin-right: 3.3333%;
  max-width: 480px;
  background-color: var(--lightyellow);
  align-items: center;
  line-height: 1;
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
}

.RadioButtonContainerActive {
  @extend .RadioButtonContainer;
}

.RadioButtonContainer div:not(:last-of-type) {
  margin-right: 1.5rem;
}

.RadioButtonContent {
  letter-spacing: 0;
  color: #0c1026;
  opacity: 1;

  label {
    cursor: pointer;
  }
}