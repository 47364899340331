@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/media';

.PromoPeriodCounterGreen {
  background-color: #dcfad9;
  height: 47px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .Icon {
    height: 29px;
  }
  p {
    padding: 0;
    margin: 0;
  }
  span {
    padding: 0 5px;
  }
}
.PromoPeriodCounterOrange {
  @extend .PromoPeriodCounterGreen;
  background-color: #fff6cf !important;
}

.MainNavigation {
  top: 0;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  background: $darkblue;
  @media all and (max-width:$maxMobile) {
  height: 50px;
  }
}

.NavTopContainer {
  width: 80%;
  margin: 0 auto;
  padding: 0 8em;
  @media all and (max-width:$maxMobile) {
    height: 50px;
    margin: 0;
    padding: 0;
  }
}

.NavTopContent {
  @include justify-between;
  @include align-center;
}

.Logo {
  color: $green;
  font-size: 2.5em;
  font-family: $fontBold;
  cursor: pointer;
  @media all and (max-width:$maxMobile) {
    font-size: 1.25em;
    padding-top: 12px;
    padding-left: 17px;
  }

  &:hover {
    color: $green;
  }
}

.UserContainer {
  display: flex;

  img {
    margin-right: 1em;
  }
}

.Username {
  cursor: pointer;
  font-family: $fontLight;
  color: $green;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 1em;
}

.Pipe {
  color: #4186a3;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 1em;
}

.PartnerName {
  font-family: $fontLight;
  color: $green;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 1em;
}

.ChangeLinkContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-right: 1em;
  text-decoration: underline;
  cursor: pointer;
  color: white;
  font-family: $fontLight;
  font-size: 16px;
}

.IconUser {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  @include svg-icon(
    '../../../layouts/images/icon-user.svg',
    -1px,
    20px,
    $green
  );
  height: 100%;
}

.NavBottomContainer {
  min-height: 4em;
}

.NavBottomContent {
  background: $green;

  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  padding: 1.2em;
}

.NavModeratorContent {
  width: 100%;
  margin: 0 auto;
  padding: 5px 1.2em;
}

.Navigation {
  display: flex;
  margin: 0 auto;

  .LinkWrapper {
    @include flex-center-column;
  }

  span {
    place-content: space-between;
    color: $darkblue;
    margin-right: 3em;
    font-family: $fontPrimary;
    font-size: 16px;
    position: relative;
    text-decoration: none;

    &:hover {
      border-bottom: 2px solid $darkblue;
      color: $darkblue;
    }
  }
}

.ModeratorTopNav {
  .NavigationModerator {
    display: flex;
    margin: 0 auto;

    .LinkWrapper {
      @include flex-center-column;
    }

    span {
      place-content: space-between;
      border-color: $green;
      color: white;
      margin-right: 3em;
      font-family: $fontLight;
      font-size: 16px;
      position: relative;
      text-decoration: none;
    }

    span:hover {
      border-bottom: 2px solid $green;
      color: $white;
    }
  }

  .ActiveModerator {
    span {
      border-bottom: 2px solid $green;
      color: $white;
    }
  }

  .InactiveModerator {
    cursor: pointer;
    display: contents;
    color: white;
    font-family: $fontPrimary;
    font-size: 18px;
  }
}

.Active {
  span {
    border-bottom: 2px solid $darkblue;
    color: $darkblue;
  }
}

.TranslationSwitcherContainer {
  // removed per client's request
  display: none;
  float: right;
  margin-top: 3rem;
  margin-right: 1rem;
}

