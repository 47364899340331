.wrapper {
  width: 1000px;
  margin: 20px auto;
  text-align: left;
}

.currentlyShownHistoricalInvoice {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.arrowBack {
  margin: 0;
  height: 100%;
}

.textBack {
  text-decoration: underline;
}

.historicalInvoicesMonthsWrapper {
  width: 600px;
  margin: 0 auto;
}

.yearText {
  text-align: left;
  margin-top: 40px;
  margin-bottom: 20px;
}

.yearsTabs {
  text-align: left;
}

.monthlyInvoiceBlock {
  display: flex;
  background: white;
  margin: 25px auto;
  box-shadow: 0px 3px 6px #00000029;
}

.detailedTextWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  background: #dbf0ff;
  width: 30%;
  text-decoration: underline;
  cursor: pointer;
}

.monthBlock {
  display: flex;
  justify-content: space-between;
  width: 70%;
  padding: 20px;
  font-size: 24px;
  font-family: Yettel-Bold;
}

.monthRsd {
  font-size: 16px;
  font-family: Yettel;
}

.noInvoicesMessage {
  margin-top: 100px !important;
}

.finalSum {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background: white;
  font-family: Yettel-Bold;
  margin-top: 75px;
}
