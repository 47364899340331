@import "src/assets/styles/colors";
@import "src/assets/styles/mixins/index";

.ExpirationInfo {
  @include flex-center-vertical;
  position: relative;
  top: 0;
  width: 100%;
  height: 24px;
  text-align: left;
  &.Danger {
    .ExpirationText {
      color: $lightred;
    }
  }
  &.Warning {
    .ExpirationText {
      color: $lightorange;
    }
  }
  .InfoLine {
    @include flex-center-vertical;
  }
  .ExpirationStringWrapper {
    @include flex-center-vertical;
    text-align: left;
    // This line commented until distance calculation is implemented
    //max-width: 60%;
    &.FullWidth {
      max-width: unset;
      width: 100%;
      white-space: nowrap;
    }
  }
  .ExpirationIcon {
    position: relative;
    width: 1rem;
    height: auto;
    margin-right: 0.33rem;
  }
  .ExpirationText {
    position: relative;
    color: $validOffer;
    line-height: 1.1;
    margin-bottom: 0;
    font-family: $fontMedium;
    font-size: 16px;
    &.ListText {
      font-size: 14px;
    }
  }
  .DistanceStringWrapper {
    @include flex-center-vertical;
    text-align: left;
    width: 40%;
  }
  .CountdownExpirationText {
    position: relative;
    font-family: $fontMedium;
    color: $lightred;
    &.ListText {
      font-size: 16px;
    }
  }
  .Divider {
    margin: 0 0.33rem;
  }
  .Distance {
    @include flex-center-vertical;
  }
  .DistanceText {
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.2;
    align-items: center;
  }
  .DistanceIcon {
    position: relative;
    height: 1rem;
    width: auto;
    margin-right: 0.33rem;
  }
}
.YettelPayIcon {
  width: 30px;
}
