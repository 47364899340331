@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.SearchFieldContainer {
  display: flex;
  flex-direction: row;
  background: transparent;
  width: 100%;
  border-bottom: 1px solid var(--lightgrey);
  .InnerSearchFieldContainer {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: row;
    width: 100%;
    > .SearchField {
      outline: none;
      width: 100%;
      background-color: transparent;
      border: none;
    }

    > .SearchIconContainer {
      @include svg-icon('../../icons/search.svg', 24px, 24px, #0c1026);
    }
  }
}

input:focus {
  outline: none;
}

input {
  outline: none;
}
