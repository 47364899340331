@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/media';
@import 'src/assets/styles/mixins';

$md : map-get($gridBreakpoints, MD);

.PageTitle {
  margin: 0 0 0 0;
  padding-top: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: $darkblue;
  background: transparent;
  @media screen and (min-width: $md) {
    font-size: 30px;
    padding-top: 2rem;
    background: white;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    background-color: white;
    @media all and (max-width:$maxMobile) {
      background-color: $background;
    }
  }
}

.CompanyProfileTabs {
  padding: 0 1rem;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  @media all and (max-width:$maxMobile) {
    li {
      color: #4F687D!important;
      &[aria-selected="true"] {
        color: $darkblue!important;
      }
    }
    white-space: nowrap;
    overflow-y: hidden;
    max-width: 100%;
  }
}


.TabsContainer {
  @media screen and (min-width: $md) {
    background: white;
    > .PageTitleContainer {
      width: 100%;
      height: 100%;
      align-content: center;
      align-items: center;
      text-align: center;
      background-color: white;
      @media all and (max-width:$maxMobile) {
        background-color: #f2f2f2;
      }
      > .PageTitle {
        text-align: center;
        font-size: 30px;
        font-weight: bold;
        margin: 2rem 0 0;
        color: $darkblue;
        opacity: 1;
      }
    }
  }
}

.Content {
  padding: 1.5rem 1rem;
  @media screen and (min-width: $md) {
    display: flex;
    justify-content: center;
    gap: 4%;
    padding: 3%;
  }
}

.Column {
  margin: 0 auto;
  text-align: left;
  @media screen and (min-width: $md) {
    flex: 1 0 20%;
  }
  > div {
    margin-bottom: 1rem;
  }
  label {
    display: block;
    font-size: 16px!important;
  }
}

.FileInputContainer {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  .FileInput {
    display: flex;
    flex-direction: column;
  }
}

.Disabled {
  pointer-events: none;
}

.UploadContainer {
  border: 1px solid #707070;
  border-radius: 50%;
  opacity: 1;
  width: 294px;
  height: 294px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  img {
    margin: 0;
    height: auto;
    width: 100%;
  }
  .PlusIcon {
    height: 32px;
    width: 32px;
  }
}

.ButtonContainerBackground {
  padding: 1rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: sticky;
  bottom: 0;
  @media screen and (min-width: $md) {
    background-color: transparent;
    position: static;
  }
  .ButtonContainer {
    margin: 0 auto;
    width: fit-content;
  }
}

.ChangePicture {
  cursor: pointer;
  margin-top: 25px;
  text-align: center;
  text-decoration: underline;
}


