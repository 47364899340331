@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
}

.UploadCodesContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;

  .ContinueContainer {
    flex: 1;
    margin-top: auto;
    margin-bottom: 0.9em;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .ButtonAddTable {
      width: 50%;
    }

    > .PersonalizedCodesText {
      margin-top: 1em;
      padding: 1em;
      background: var(--lightred);
    }
  }

  .CodeTypeContainer {
    margin-left: 2em;

    > .CodeTypeLabel {
      margin-bottom: 1rem;
      text-align: left;
      letter-spacing: 0px;
      color: #0c1026;
      height: 2rem;
    }
  }
}

.UploadContent {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2em;

  .DragAndDrop {
    width: 100%;
    min-width: 300px;
    min-height: 200px;
    border: 1px solid #707070;
    border-radius: 20px;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5em;
    @media all and (max-width:$maxMobile) {
      margin-right: 0;
      background-color: #ffffff;
    }
    .Upload {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .DragAndDropIcon {
      height: 32px;
      width: 32px;
    }
  }

  .FileUpload {
    width: 100%;

    .UploadCodesButtonContainer {
      line-height: 1;
      border-radius: 2rem;
      border: 2px solid var(--darkgrey);
      text-decoration: none;
      color: var(--darkblue);
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-weight: normal;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      .UploadContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .UploadImage {
          height: 30px;
          width: 30px;
          margin-left: 10px;
        }
      }
    }
  }
}

.DownloadLinkContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .DownloadLink {
    color: $darkblue;
    margin-top: 3em;
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Yettel';
    font-size: 16px;
  }
  .DownloadIcon {
    position: relative;
    top: 8px;
    left: 8px;
    height: 32px;
    width: 32px;
  }
}
