@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.SelectContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (max-width: $maxMobile) {
    margin-bottom: 1rem;
  }
  > .SelectLabel {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
    color: $darkblue;
  }

  > .SelectLabelCentered {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    color: $darkblue;
  }

  .SelectContent {
    display: flex;
    flex-direction: row;
    background: white;
    width: 100%;
    border: 1px solid var(--lightgrey);
    border-radius: 8px;

    .SearchRadius {
      border-radius: 8px;
    }
  }

  .DropdownIconContainer {
    display: flex;

    .DropdownIcon {
      display: block;
      margin: auto auto auto 8px;
    }
  }

  .SelectArrowsContainer {
    pointer-events: none;
    position: relative;
    bottom: 2em;
    z-index: 2;

    .SelectArrowsInnerContainer {
      float: right;
      margin-right: 0.5em;

      > .UpArrow {
        @include svg-icon('../../icons/dropdown-up.svg', 6.5px, 5px, #373a3c);
      }

      > .DownArrow {
        @include svg-icon('../../icons/dropdown.svg', 6.5px, 5px, #373a3c);
      }
    }
  }
}

.Required {
  color: var(--requiredstar);
}
