@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.Container {
  @include flex-column;
  position: fixed;
  z-index: 9999;

  &:global(.TopLeft) {
    top: $sidePadding;
    left: $sidePadding;
  }

  &:global(.TopRight) {
    top: $sidePadding;
    right: $sidePadding;
  }

  &:global(.BottomLeft) {
    bottom: $sidePadding;
    left: $sidePadding;
  }

  &:global(.BottomRight) {
    bottom: $sidePadding;
    right: $sidePadding;
  }
}

.Item {
  background-color: transparent;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5rem $colorBoxShadow;
  overflow: hidden;
  padding: 0;
  pointer-events: auto;
  position: relative;
  margin-bottom: 1rem;
  transition: all 0.25s ease;
  width: 300px;

  &:global(.TopLeft),
  &:global(.BottomLeft) {
    animation: toast-in-left 0.5s;
    transition: transform 0.5s ease-in-out;
  }

  &:global(.TopRight),
  &:global(.BottomRight) {
    animation: toast-in-right 0.5s;
    transition: transform 0.5s ease-in-out;
  }
}

.CloseIcon {
  cursor: pointer;
  fill: $colorTextLight;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
}

@keyframes toast-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes toast-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(125%);
  }
}

@keyframes toast-in-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes toast-out-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-125%);
  }
}
