@import 'src/assets/styles/mixins';

.Container {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 18px;
  font-family: $fontPrimary;
  cursor: pointer;
}

.DownloadImage {
  cursor: pointer;
  margin-right: 0.1em;
}
