@import 'variables';
@import 'colors';
@import 'fonts';
@import 'media';
@import 'general';
@import 'grid';
@import 'mixins';
@import 'google';
@import 'date';
@import 'error';
@import 'global';
@import 'typography';

.my-slider {
  .rc-slider-rail {
    height: 7px;
    background: #e5e9ec 0% 0% no-repeat padding-box;
    border-radius: 4px;
  }
  .rc-slider-track {
    border: #002340 5px solid;
  }
  .rc-slider-handle {
    background: #fff 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 17px;
    height: 17px;
    border: #002340 5px solid;
  }
  .rc-slider-handle-dragging {
    color: none !important;
    border-color: unset !important;
    box-shadow: unset !important;
  }
  .rc-slider-dot {
    display: none;
  }
}
