@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.TagContainer {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
  text-decoration: none;
  color: var(--darkblue);
  line-height: 1;
  background: var(--bgblue);
  border: 1px solid var(--darkgrey);
  border-radius: 30px;
  padding: 0.5rem 2rem;
  margin-bottom: 1rem;
  height: 48px;
  width: 200px; //experimental
  > .TagText {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  > .XBtn {
    position: relative;
    width: 1.5rem;
    margin-left: 1rem;
    margin-right: -1rem;
    cursor: pointer;
    > .XBtnIcon {
      @include svg-icon('../../../assets/icons/rem.svg', 24px, 24px, black);
    }
  }
  > .XBtnDisabled {
    @extend .XBtn;
    cursor: not-allowed;
  }
}

.TagContainerDisabled {
  @extend .TagContainer;
  background: #fbfeff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
}
