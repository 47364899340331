.CheckboxGroupContainer {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.5rem;
  }
  .CheckboxContainer {
    border-bottom: 1px solid #b8b8b8;
    > * {
      margin-bottom: 1rem;
    }
  }
}
