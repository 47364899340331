@import "../../../../components/domain/partner-ad-list/partner-ad-list-elem/PartnerAdListElem.module";

.Title {
  cursor: pointer;
  text-decoration: underline;
}

.CenterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;

  img {
    cursor: pointer;
  }
}

.Container {
  justify-content: unset;

  > .MainContentContainer {
    flex: 1;
  }
}
