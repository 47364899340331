.modalWrapper {
  padding: 25px 50px;
  text-align: left;
  font-size: small;
}

.title {
  font-size: 22px;
  text-align: center;
  margin-bottom: 40px;
}

.table {
  margin-bottom: 50px;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  text-align: left;
  margin-bottom: 20px;
  font-size: 16px;
}

.tableRow span:nth-child(2) {
  font-weight: bold;
}

.activePacketName {
  font-size: 20px;
  font-family: Yettel-Bold;
}

.tableRowDiscounts {
  display: flex;
  flex-direction: column;
}

.discountsTitle {
  font-size: 18px;
  padding: 20px 0;
}

.discountsWrapper {
  display: flex;
  font-size: 16px;
}

.buttonsWrapper {
  font-size: 18px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 100px;
  gap: 50px;
}

.buttonsWrapperRejection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  font-size: 18px;
}

.saveButton {
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  border: 2px solid var(--darkblue);
  background-color: var(--darkblue);
  color: white;
  width: 200px;
}

.giveUpButton {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 0;
  color: var(--darkblue);
  text-decoration: underline;
  margin-top: 10px;
}

.buttonsWrapper button {
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  width: 250px;
  border: 2px solid var(--darkblue);
}

.rejectionReasonTextAreaWrapper {
  font-size: 18px;
  margin-top: 50px;
}

.rejectButton {
  background-color: white;
}

.approveButton {
  background-color: var(--darkblue);
  color: white;
}

.packagesDropdownWrapper {
  width: 75%;
  font-size: 18px;
}
