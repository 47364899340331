@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.TextSection {
  .Tab {
    width: 100%;
    color: white;
    overflow: hidden;
    .TabLabel {
      display: flex;
      align-items: center;
      padding: 0 8px;
      height: 35px;
      background: $filterbg;
      color: $darkblue;
      font-size: 1.1rem;
      font-family: $fontMedium;
    }
    .TabContent {
      padding: $textSectionPadding;
      color: $darkblue;
      background: white;
      transition: all 0.35s;
      p {
        position: relative;
        margin-top: 0;
        margin-bottom: 4px;
        text-align: left;
        font-size: 1em;
        font-family: $fontRegular;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      h5 {
        text-align: left;
        line-height: normal;
      }
      ul {
        position: relative;
        margin-top: 0;
        text-align: left;
        line-height: normal;
        margin-left: 0;
        padding-inline-start: 0.9765625vw !important;
      }
      li {
        list-style-type: disc !important;
        padding: 0;
        margin: 0;
        font-size: 1em !important;
      }
    }
  }
}
