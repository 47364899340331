@import 'mixins';

body {
  color: $colorText;
  font-family: $fontPrimary;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: $colorBackground;
}

ul {
  list-style: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}

a {
  color: $colorPrimary;
  text-decoration: none;
  transition: color 0.25s ease-in-out;

  &:hover {
    color: $colorText;
    text-decoration: none;
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontPrimary;
  color: $colorTitle;
  margin-top: 0;
}

h1 {
  @include font-size(36, 44, 600);
  margin-bottom: 2.25rem;
}

h2 {
  @include font-size(32, 40, 600);
  margin-bottom: 2rem;
}

h3 {
  @include font-size(28, 36, 600);
  margin-bottom: 1.75rem;
}

h4 {
  @include font-size(24, 32, 600);
  margin-bottom: 1.5rem;
}

h5 {
  @include font-size(20, 28, 600);
  margin-bottom: 1.25rem;
}

h6 {
  @include font-size(16, 24, 600);
  margin-bottom: 1rem;
}

p {
  @include font-size(16, 24, 400);
  margin-top: 0;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}


.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.root {
  display: flex;
  min-height: 100%;
}

.ClearFix {
  display: flex;
  overflow: auto;
}

.TitleNumber {
  @include font-size(16, 32, 600);
  background-color: $colorPrimary;
  border-radius: 50%;
  color: $colorTextLight;
  display: inline-block;
  height: 2rem;
  margin-left: 0.75rem;
  text-align: center;
  width: 2rem;
}

.NotificationCircle {
  @include font-size(16, 32, 600);
  background-color: $colorPrimary;
  border-radius: 50%;
  color: $colorTextLight;
  display: inline-block;
  height: 2rem;
  margin-left: 0.75rem;
  text-align: center;
  width: 2rem;

  svg {
    height: 1.25rem;
    margin: 0.375rem;
    width: 1.25rem;

    path {
      fill: $colorTextLight;
    }
  }
}

.DisplayFlex {
  display: flex;
}

.SubmitContainer {
  position: relative;
  top: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > .SubmitBtn {
    position: relative;
    margin: 0 auto;
    background: none;
    border: none;
    font-size: 1.125rem;
    letter-spacing: 1px;
    color: var(--white);
    line-height: 1;
    background-color: var(--darkblue);
    border-radius: 24px;
    padding: 1rem 3rem;
    transition: opacity 0.3s;
    cursor: pointer;
  }
}

.text-align-center {
  text-align: center;
}

.small-icon {
  width: 24px;
  height: 24px;
}

.medium-icon {
  width: 32px;
  height: 32px;
}

.large-icon {
  width: 48px;
  height: 48px;
}