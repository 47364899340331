@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Btn {
  position: relative;
  margin: 0 auto;
  border: none;
  color: var(--white);
  line-height: 1;
  background-color: var(--darkblue);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
  font-size: 9px;

  &:hover {
    opacity: 0.75;
  }
}

.BtnDisabled {
  @extend .Btn;
  background-color: var(--lightgrey);
  cursor: not-allowed;
}
