@import 'src/assets/styles/colors';
@import 'src/assets/styles/media';

.InputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;

  > .InputContent {
    display: flex;
    flex-direction: row;
    > .InputField {
      width: 100%;
      cursor: pointer;
      flex-basis: 100%;
      border: 1px solid var(--lightgrey);
      padding: 1rem;
      border-radius: 8px;
      background-color: white;
      @media all and (max-width: $maxMobile) {
        line-height: 20px;
      }
      &.Disabled {
        background-color: var(--lightgrey);
        cursor: not-allowed;
      }
    }
    > .ArrowsHidden::-webkit-outer-spin-button,
    .ArrowsHidden::-webkit-inner-spin-button,
    .ArrowsHidden {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }

    > .InputFieldInvalid {
      @extend .InputField;
      border: 1px solid var(--darkred);
    }
  }

  > .InputLabel {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
    color: $darkblue;
  }

  > .ErrorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    > .Error {
      width: 100%;
      display: flex;
      align-items: flex-start;
      color: var(--darkred);
    }
  }
}

.Required {
  color: var(--requiredstar);
}

.EyeContainer {
  border-radius: 0 8px 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.2em;
  background-color: white;
  cursor: pointer;
  margin-left: -8px;
  border-right: 1px solid var(--lightgrey);
  border-top: 1px solid var(--lightgrey);
  border-bottom: 1px solid var(--lightgrey);
  > img {
    margin-right: 0;
  }
  @media all and (max-width: $maxMobile) {
    padding: 0;
    width: 35px;
    position: relative;
    margin-left: -8px;
    padding-right: 8px;
    >img {
      width: 100%;
      height: auto;
    }
  }
}

.EyeContainerInvalid {
  @extend .EyeContainer;
  border-right: 1px solid var(--darkred);
  border-top: 1px solid var(--darkred);
  border-bottom: 1px solid var(--darkred);
}
