@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/mixins';

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: transparent;

  .Left{
    text-align: left;
  }

  .LeftContainer {
    text-align: left;
    display: flex;
    align-content: center;
    align-items: center;
    @media all and (max-width:$maxMobile) {
      width: 100%;
      padding: 1em;
    }
    img {
      margin-right: 0;
    }
    span{
      text-decoration: underline;
    }
  }

  > .PageTitleContainer {
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    text-align: center;

    > .PageTitle {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      padding-top: 2rem;
      padding-bottom: 1rem;
      color: $darkblue;
      opacity: 1;
      &.NoTabs {
        margin: 2rem 0;
      }
    }
  }
}

.CenteredTextContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
}

.ElementMargin {
  margin-bottom: 2em;
}

.TabSection {
  margin-bottom: 2em;
  @media all and (max-width:$maxMobile) {
    margin: 0;
    padding: 1em;
    font-size: 18px;
  }
  cursor: pointer;
  .Tab {
    width: 100%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    .TabLabel {
      display: flex;
      align-items: center;
      padding: 0 8px;
      height: 35px;
      width: 100%;
      color: $darkblue;
      cursor: pointer;
      font-size: 18px;
      font-family: $fontMedium;
      @media all and (max-width:$maxMobile) {
        text-align: left;
      }
    }
  }
}

.MainContentContainer {
  transition: all 0.35s;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  width: 90%;
  height: fit-content;
  margin: 0 auto;
  @media all and (min-width:$maxMobile) {
    border-radius: 10px;
    width: 80%;
    padding: 2em;
  }

  > .Elements {
    display: flex;
    flex-direction: column;
    justify-content: left;
    justify-items: left;
    align-items: left;
    align-content: left;
    padding-right: 20%;

    > .Element {
      display: flex;
      flex-direction: column;
      justify-content: left;
      justify-items: left;
      align-items: left;
      align-content: left;
      width: fit-content;
      height: fit-content;
      div {
        text-align: left;
      }
      p {
        text-align: justify;
      }
      img {
        height: 100%;
        width: 100%;
        margin-top: 0.5em;
        margin-bottom: 28px;
      }
    }
  }
}

.BottomBorder {
  @media all and (min-width:$maxMobile) {
    border-bottom: 1px solid #b8b8b8;
  }
}

.ImgBorder {
  border: 3px solid #b8b8b8;
}
