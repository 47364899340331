@import 'src/assets/styles/colors';

.OfferStepsContainer {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  display: flex;

  > .StepContainer {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    > .StepNumberContainer {
      background: #eff8ff 0% 0% no-repeat padding-box;
      opacity: 1;
      margin-bottom: 1rem;
      border-radius: 50%;
      width: 4em;
      height: 4em;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      justify-items: center;

      > .StepNumber {
        text-align: center;
        letter-spacing: 0px;
        color: #0c1026;
        opacity: 1;
      }
    }

    > .PassedStepNumberContainer {
      @extend .StepNumberContainer;
      background: #f1ffcf 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    > .StepText {
      text-align: center;
      letter-spacing: 0px;
      color: $darkblue;
      opacity: 1;
    }
  }
}

.StepLine {
  width: 100%;
  height: 0.5px;
  margin-top: 2em;
  background-color: #5a7184;
  justify-self: center;
}
