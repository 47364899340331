@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.OuterDateFieldsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .DateFieldsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #a4a4a4;
    border-radius: 2px;
    opacity: 1;

    > .PickLinkContainer {
      width: 20%;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;

      .PickLink {
        cursor: pointer;
        text-align: left;
        color: var(--blue);
        opacity: 1;
        font-family: 'Yettel';
        font-size: 18px;
      }
    }

    > .InnerDateFieldsContainer {
      display: flex;
      width: 80%;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 1em;
      margin-left: 1em;
    }
  }
}

.PickDateBtn {
  @include svg-icon('../../images/icon-date.svg', 24px, 24px, black);
  margin-right: 1em;
}

.PickDateBtnDisabled {
  @extend .PickDateBtn;
  @include svg-icon('../../images/icon-date.svg', 24px, 24px, grey);
}

.DateContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.LowMarginLabel {
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  color: $darkblue;
}

.DateLabel {
  text-align: center;
}

.MainOuterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Disabled {
  pointer-events: none !important;
  visibility: hidden !important;
}
