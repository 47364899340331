@import 'src/assets/styles/variables';

.Container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  > .ColumnsContainer {
    display: flex;
    width: fit-content;
    flex-direction: row;
    width: 100%;
    > .Column {
      display: flex;
      flex-direction: column;
      width: 50%;
      min-height: 60vh;
      max-height: fit-content;
      margin-left: 1em;
      margin-right: 1em;

      > .FieldsContainer {
        margin-top: 1em;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        > .InputFieldContainer {
          display: flex;
          width: 100%;
          margin-bottom: 0.5em;
        }
        > .NoteContainer {
          @extend .InputFieldContainer;
          text-align: justify;
          letter-spacing: 0px;
          margin-bottom: 2em;
          font-family: $fontLight;
        }
      }
      .UploadBtnContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: flex-start;
        .CodeTypeLabel {
          margin-bottom: 10px;
        }
        .ContractContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-top: 1px solid #67797a;
          height: 47px;
          * {
            margin: 0;
          }
          p {
            font-size: 14px;
          }
          .DownloadDeleteContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            .DeleteIcon {
              margin-left: 20px;
              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        .ContractContainer:last-child {
          border-bottom: 1px solid #67797a;
        }
      }
    }
  }

  > .SaveBtnContainer {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-items: center;
    align-items: center;
  }
}

.Link {
  color: var(--darkblue);
  font-size: 18px;
  font-family: $fontPrimary;
  cursor: pointer;
  text-decoration: underline;
  margin: 30px 0;
}
