@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: transparent;

  > .FormContainer {
    border-radius: 10px;
    background-color: white;
    margin-top: 2em;
    margin-left: 20%;
    margin-right: 20%;

    > .FormContent {
      max-height: fit-content;
      opacity: 1;
      margin-bottom: 2rem;
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;

      > .IconContainer {
        margin-bottom: 1rem;
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        height: fit-content;

        > .Icon {
          height: 96px;
          width: 96px;
        }
      }

      > .TextContainer {
        display: flex;
        flex-direction: column;
        justify-items: center;
        width: 60%;

        > .OfferSentTitle {
          margin-bottom: 1rem;
          text-align: center;
          letter-spacing: 0px;
          color: #0c1026;
        }

        > .OfferSentContent {
          text-align: center;
          letter-spacing: 0px;
          color: #0c1026;
        }
      }
    }
  }
}

.Link {
  cursor: pointer;
  font-family: $fontPrimary;
  font-size: 18px;
  color: $darkblue;
  text-decoration: underline;
}
