@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.BuyAdditionalLocationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    margin-bottom: 1em;
    color: $darkblue;
  }

  .Link {
    color: $darkblue;
    text-decoration: underline;
    font-size: 0.75em;
    cursor: pointer;
  }
}

.PriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .PriceContent {
    display: flex;
  }

  .Price {
    font-weight: bold;
    color: $darkblue;
    margin-right: 1em;
  }

  .PriceSpan {
    margin-bottom: 1em;
  }
}

.AdditionalLocationText {
  text-align: left;
  margin-bottom: 0;
}

.AddAdditional {
  background-color: $locationblue;
  position: relative;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2.5rem;
  margin-top: 1rem;
  margin-bottom: 1em;
}
