@import 'src/assets/styles/colors';

.Container {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-bottom: 0.5rem;
  text-align: left;
  width: 100%;
  color: $darkblue;
  border-bottom: 1px solid $bottomBorder;
  > .Item {
    margin-bottom: 0.5em;
  }
}
