@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.TextAreaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  > .InputLabel {
    position: relative;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
    color: $darkblue;
  }
}

.TextAreaLabel {
  text-align: left;
}

.CharacterLimit {
  display: inline-block;
  margin-left: 6px;
  font-size: 12px;
}

.TextAreaContent {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  font: medium -moz-fixed;
  @include border(1, solid, var(--lightgrey), 4);
  height: 100%;
  overflow: hidden;
  padding: 2px;
  resize: both;
  width: 100%;
  font-family: $fontPrimary;
  border-radius: 8px;
  &:focus {
    outline: none !important;
  }

  .SmallTextArea {
    min-height: 100px;
  }

  .LargeTextArea {
    min-height: 200px;
  }

  > .TextAreaButtons {
    display: flex;

    > .RichTextButton {
      @include rich-text-button;

      > .RichTextIconBold {
        @include svg-icon(
                        '../../../assets/images/icon-bold.svg',
                        20px,
                        20px,
                        $darkblue
        );
      }

      > .RichTextIconItalic {
        @include svg-icon(
                        '../../../assets/images/icon-italic.svg',
                        20px,
                        20px,
                        $darkblue
        );
      }

      > .RichTextIconBullet {
        @include svg-icon(
                        '../../../assets/images/icon-bulleted-list.svg',
                        20px,
                        20px,
                        $darkblue
        );
      }
    }

    > .RichTextButtonActive {
      @extend .RichTextButton;
      background: $bgfeatured;
    }
  }
}

.ErrorContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;

  > .Error {
    width: 100%;
    display: flex;
    align-items: flex-start;
    color: var(--darkred);
  }
}

.Required {
  color: var(--requiredstar);
}
