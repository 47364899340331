@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
.PaginationContainer {
  display: flex;
  list-style-type: none;

  .PaginationItem {
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: #80919f !important;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 18px !important;
    height: 42px;
    min-width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .Prev {
      img {
        margin: 0;
        height: 26px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55px;
      width: 55px;
      border: 2px solid #002340;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        // border-radius: 50%;
        cursor: pointer;
      }
    }
    .Next {
      @extend .Prev;
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        // border-radius: 50%;
        cursor: pointer;
      }
    }
    .Gray {
      @extend .Prev;
      border: 2px solid#80919f;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 50%;
      cursor: pointer;
    }

    &.Selected {
      background-color: rgba(0, 0, 0, 0.08);
      border-radius: 50%;
      height: 42px;
      width: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $darkblue !important;
    }

    .Arrow {
      &::before {
        position: relative;
        content: '';
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.Left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.Right {
        transform: rotate(45deg);
      }
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }

    &.Disabled {
      pointer-events: none;

      .Arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
  .Dots {
    display: flex;
    align-items: center;
    &:hover {
      background-color: transparent;
      cursor: default;
      display: flex;
      align-items: center;
    }
  }
}
