.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 50px 10px 50px;
}

.LeftSideContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.RightSideContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.SearchInputContainer {
  width: 100%;
}

.ColumnsSelectorContainer {
  width: 100%;
}

.ExportButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  img {
    margin: 0;
    width: 2em;
    height: 2em;
  }

  span {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
}

.PageSizeDropdownContainer {
  display: flex;
  width: 200px;
}

.saveTable {
  font-size: 18px;
}

.numberOfAdsPerPage {
  font-family: Yettel-Light;
}
