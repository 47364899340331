@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.Container {
  @media all and (min-width:$maxMobile) {
    background-color: #f2f2f2;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
  }


  > .Section {
      @media all and (min-width:$maxMobile) {
        box-shadow: 0px 3px 6px #00000029;
        width: 80%;
        padding: 0.7em 1.5em 3.5em;
      }
      margin-top: 3em;
      border-radius: 10px;
      background-color: white;
      display: flex;
      flex-direction: column;

    > .TableContent {
      display: flex;
      width: 100%;
      justify-items: center;
      align-items: center;
      justify-content: center;
      align-content: center;
      @media all and (max-width:$maxMobile) {
        overflow-x: scroll;
      }

      > table {
        display: flex;
        flex-direction: column;
        width: 100%;
        table-layout: fixed;
        overflow: auto; 
        @media all and (max-width:$maxMobile) {
          > tbody {
            display: grid;
            width: 100%;
            justify-items: center;
            align-items: center;
            align-content: center; 
          } 
        }
      }
    }
  }
}

.HeaderRow {
  background-color: var(--darkblue);
  @media all and (max-width:$maxMobile) {
    width: max-content;
  }
}

.Left {
  width: 100%;
  text-align: left;
  margin-right: 0.5em;
}

.Right {
  width: 100%;
  justify-content: end;
  margin-right: 2em;
  margin-right: 0.5em;
}

.Resend {
  width: 100%;
  justify-content: flex-start;
  margin-right: 2em;
}

.JustifyCenter {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.Container tr {
  display: flex;
  flex-direction: row;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  border-bottom: 0.5px solid #a4a4a4;

  > th {
    display: flex;
    flex-direction: row;
    text-align: left;
    color: white;
    font-family: $fontPrimary;
    font-weight: normal !important;
    @media all and (max-width:$maxMobile) {
      width: 150px;
    }
  }

  > td {
    display: flex;
    flex-direction: row;
    text-align: left;
    color: var(--darkblue);
    font-family: $fontPrimary;
    @media all and (max-width:$maxMobile) {
      width: 150px;
    }
    overflow-wrap: anywhere;
  }
}

.Link {
  text-decoration: underline;
  color: var(--darkblue);
  font-size: 18px;
  font-family: $fontPrimary;
  cursor: pointer;
}

.AddAdminContainer {
  padding-top: 1em;
  @media all and (max-width:$maxMobile) {
    background-color: $background;
  }

  > .Plus {
    margin-right: 0.25em;
  }
}

.Edit {
  height: 32px !important;
  cursor: pointer;
  margin-right: 0.2em;
}

.Delete {
  @extend .Edit;
  margin-left: 1.3em;
}

.Hidden {
  visibility: hidden;
  pointer-events: none;
}

.Break {
  @media all and (min-width:$maxMobile) {
    line-break: anywhere;
  }
}
