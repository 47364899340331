@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

p {
  margin: 0;
  padding: 14px;
}

.filterHeader {
  display: flex;
  gap: 0.5em;
  padding: 10px 10px 0 10px;
  align-items: center;
}

.filterIcon {
  margin-right: 0;
}

.dropdownMultiselectWrapper {
  display: inline-block;
  position: relative;
  width: 100%;
}

.dropdownMultiselectLabel {
  position: absolute;
  left: 0;
  top: -50%;
  color: #878787;
}

.dropdownMultiselect {
  border: 1px solid var(--lightgrey);
  border-radius: 8px;
  position: relative;
  z-index: 10;
  background-color: white;
  cursor: pointer;
}

.dropdownMultiselectOptionsWrapper {
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 10;
  border: 1px solid var(--lightgrey);
  border-radius: 8px;
  background-color: white;
  box-shadow: 1px 1px 25px var(--lightgrey);
}

.dropdownMultiselectOptions {
  max-height: 350px;
  overflow-y: auto;
}

.dropdownMultiselectOption {
  display: flex;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--darkblue);
    label {
      color: white;
    }
  }
}

.resetButton {
  padding: 10px;
  width: fit-content;
  margin: 0 auto;
  font-family: Yettel-Bold;

  div {
    font-size: 16px;
    padding: 16px 28px;
  }
}
