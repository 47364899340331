@import 'src/assets/styles/variables';
@import 'src/assets/styles/media';

.react-tabs {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  padding: 0;
  @media screen and (max-width: $maxMobile) {
    height: 31.5px;
    overflow: scroll;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }
  }
}

.react-tabs__tab-list * {
  margin: 0 15px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  color: var(--greynotselected);
  font-family: 'Yettel';
  font-size: 18px;
  @media screen and (max-width: $maxMobile) {
    white-space: pre;
    padding: 6px 6px;
    margin: 0 1em;
  }
}

.react-tabs__tab--selected {
  color: var(--darkblue) !important;
  font-family: 'Yettel';
  font-size: 18px;
  border-bottom: 3px solid var(--darkblue);
}

.react-tabs__tab--disabled {
  color: var(--greynotselected);
  cursor: default;
}

.react-tabs__tab:focus {
  border-bottom: 3px solid var(--darkblue);
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: transparent;
}

.react-tabs__tab-panel {
  display: none;
  background: #f2f2f2;
}

.react-tabs__tab-panel--selected {
  display: block;
}
