@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.ButtonModalContainer {
  display: flex;
  justify-content: space-around;
}

.ModalHeader {
  margin-top: 2em;
  margin-bottom: 2em;
}

.BtnNo {
  position: relative;
  margin: 0 auto;
  border: none;
  letter-spacing: 1px;
  color: var(--darkblue);
  line-height: 1;
  background-color: var(--white);
  border-radius: 48px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  cursor: pointer;
  border: var(--darkblue) 2px solid;
  font-size: 18px;

  &:hover {
    opacity: 0.75;
  }
}
