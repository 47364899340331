.Border {
  border-bottom: 1px solid;
  border-color: #054375 !important;
}

.Container {
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 2em;
  > .Title {
    font-family: 'Yettel';
    font-size: 30px;
    margin-bottom: 1.5em;
    color: '#292929';
  }
  .UserNumber {
    font-family: 'Yettel';
    font-size: 30px;
  }
  .UserText {
    @extend .Border;
    padding-bottom: 2em;
  }

  .LocationText {
    padding-top: 1em;
    padding-bottom: 0.5em;
  }

  .LocationNumber {
    @extend .Border;
    padding-bottom: 1em;
  }

  .Duration {
    padding-top: 2em;
    padding-bottom: 2em;
    @extend .Border;
  }

  .PriceText {
    margin-top: 1em;
    margin-bottom: 1em;
    color: white;
  }
}

.BaseContainer {
  @extend .Container;
  background-color: var(--mediumblue);
  > * {
    font-family: 'Yettel';
    color: #00233f;
  }
}

.PackageContainer {
  @extend .Container;
  background-color: var(--darkblue);
  .PackageTitle {
    @extend .Title;
    color: var(--green);
  }
  > * {
    font-family: 'Yettel';
    color: white;
  }
}

.OldMonthlyPayment {
  color: var(--greynotselected);
  font-size: 1em;
  text-decoration: line-through;
}

.OldMonthlyPaymentHidden {
  @extend .OldMonthlyPayment;
  visibility: hidden;
  pointer-events: none;
}

.CurrentMonthlyPayment {
  font-family: 'Yettel';
  font-size: 30px;
  color: white;
}

.ActivateBtnContainer {
  margin-top: 2em;
}

.ActivateBtnContainerHidden {
  @extend .ActivateBtnContainer;
  visibility: hidden;
  pointer-events: none;
}
