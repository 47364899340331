@import 'src/assets/styles/colors';

.ContentContainer {
  display: flex;
  flex-direction: column;

  > .TitleContainer {
    display: flex;
    flex-direction: row;
    justify-items: center;
    justify-content: center;

    > .Title {
      letter-spacing: 0px;
      color: #232323;
      opacity: 1;
    }

    margin-bottom: 2em;
  }

  > .MainContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    margin-bottom: 2em;
  }

  > .BottomContent {
    display: flex;
    flex-direction: column;
    justify-items: center;
    justify-content: center;

    > .CancelLinkContainer {
      margin-top: 1em;
      flex-direction: row;
      display: flex;
      justify-content: center;
      justify-items: center;

      > .CancelLink {
        text-decoration: underline;
        letter-spacing: 0px;
        color: $darkblue;
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
