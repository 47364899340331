@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.PhoneHeader {
  display: flex;
  flex-direction: row;
  background: var(--green);
  width: 100%;
  padding-top: 0.2em;
  padding-bottom: 0.1em;

  > .Time {
    font-size: 0.9em;
    font-weight: bold;
    line-height: 1;
    color: #000;
    margin-left: 1em;
    @include flex-center-vertical;
  }

  > .PhoneData {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > * {
      font-size: 0.6em;
      font-weight: bold;
      line-height: 1;
      color: #000;
    }

    > .Signal {
      margin-right: 0.3em;
      @include svg-icon('../../icons/cellular.svg', 10px, 10px, black);
    }

    > .Wifi {
      margin-right: 0.3em;
      @include svg-icon('../../icons/wifi.svg', 10px, 10px, black);
    }

    > .Battery {
      margin-right: 0.3em;
      @include svg-icon('../../icons/battery-full.svg', 10px, 10px, black);
    }
  }
}

.MainContent {
  display: flex;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  width: 100%;
  margin-bottom: 6em;
}
