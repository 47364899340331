@import "../colors";

@mixin spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, $green);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 10px), #000 0);
  animation: s3 1s infinite linear;
  @keyframes s3 {to{transform: rotate(1turn)}}
}
