@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.pageContainer p {
  padding: 0;
  margin: 0;
}

.title {
  font-size: 30px;
  font-family: Yettel-Bold;
  background-color: white;
  margin: 0;
  padding: 40px 0;
}

.tabs {
  background-color: white;
}

.tabs > * {
  padding: 0px 20px 20px 20px !important;
}

.tabOne {
  //margin: 0 auto;
  padding: 0 100px;
  text-align: left;
}
