@import 'src/assets/styles/variables';
.wideTD {
  max-width: unset !important;
}
.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 0.75em;
  }
  .PaginationOuterContainer {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    .DisplayedAds {
      margin: 0;
      color: var(--darkblue);
      font-size: 18px;
      margin-right: 14px;
    }
    .PageNumberInput {
      border: 2px solid #e5e9ec;
      border-radius: 8px;
      width: 74px;
      height: 52px;
      margin-left: 26px;
      text-align: center;
      font-size: 18px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      [type='number'] {
        -moz-appearance: textfield;
      }
    }
    .PageNumberInputError {
      @extend .PageNumberInput;
      border: 2px solid var(--darkred);
    }
    .PagePicker {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      span {
        margin-left: 13px;
        font-size: 18px;
        color: var(--darkblue);
      }
      .Alert {
        position: absolute;
        top: 55px;
        width: max-content;
        color: var(--darkred);
      }
    }
  }
}

.NoElementsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  justify-items: center;
  > p {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
    color: var(--darkblue);
  }
}

body {
  background-color: white;
}

.tableWrapper {
  width: 100%;
  overflow-x: auto;
  margin: 0 auto;

  // padding: 0 50px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 8px;
    background: #777;
  }
}

.table {
  border-spacing: 0;
  // min-width: 1400px;
  margin: 0 50px;
  table-layout: fixed;
  width: 100%;
  .TableHead {
    position: sticky;
    top: 0px;
    z-index: 3;
    tr {
      th {
        padding: 10px;
        font-family: Yettel-Bold;
      }
    }
  }
  // .TableHead th:first-child{
  //   padding-left: 35px;
  // }
}

.table tr:nth-child(odd) {
  background-color: white;
}
.table tr:nth-child(even) {
  background-color: #f8f8f8;
}

.table tr:nth-child(1) td {
  padding: 0;
}

.table tr:nth-child(1) td > div > img {
  margin: 17px;
}

.table td {
  padding: 10px;
  white-space: nowrap;
  //overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;
  p {
    padding: 0;
    display: inline-block;
  }
}

.table th {
  background: #dbf0ff;
  //padding: 20px 0px;
  text-align: left;
  max-width: 270px;
  overflow: hidden;
}
