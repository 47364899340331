@import 'src/assets/styles/mixins';
@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.Breadcrumbs {
  display: flex;
  flex-direction: row;
  background-color: var(--green);
  padding: 0.5em 0em 0.5em 0.5em;

  > .Inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: flex-start;
    @include flex-center-vertical;
    width: 75%;
  }

  .SearchIconWrapper {
    display: flex;
    flex-direction: row;
    width: 25%;
    justify-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
    @include flex-center-vertical;

    > .Search {
      margin-right: 0.5em;
      height: 2em;
    }
  }
}

.Link {
  display: contents;
  cursor: pointer;
}

img {
  height: 1.2em;
  margin-right: 1em;
}
.TitleText {
  font-family: $fontMedium;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5em;
  color: $darkblue;
  margin-top: 0;
}
