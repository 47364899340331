.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 22px 0;
  padding: 22px 12px;
  height: -moz-fit-content;
  height: fit-content;
  width: 800px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  opacity: 1;
}
.LeftContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.InfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.TypeContainer {
  place-self: start;
}

.Image {
  height: 100px;
  width: 100px;
  margin: 0;
  border-radius: 8px;
  object-position: center;
  object-fit: cover;
}

.Edit {
  height: 32px !important;
  cursor: pointer;
  margin-right: 0.2em;
}

.Delete {
  @extend .Edit;
  margin-left: 1.3em;
}
