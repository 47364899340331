@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.tabOneHeader {
  text-align: left;
}

.tabOneHeaderTitle {
  margin: 0;
  padding: 20px 0;
  font-size: 30px;
  font-family: Yettel-Bold;
}

.tabOneContractType {
  font-size: 22px;
}

.invoiceDates {
  margin-bottom: 20px !important;
}

.sumsTable {
  margin: 25px 0;
  padding: 20px;
  background-color: white;
}

.sumsTableRow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
  padding: 20px;
}

.sumsTableRowLast {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-family: Yettel-Bold;
}

.combinedSum {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background-color: white;
  margin-top: 50px;
  font-weight: bold;
}
