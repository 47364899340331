@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

/* TOP OFFER */

@mixin shadowOverflow {
  width: calc(100% - 40px);
}

.TopOffer {
  @include shadowOverflow;
  position: relative;
  display: block;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid $lightgrey;
  border-radius: 13px;
  text-decoration: none;
  color: $darkblue;
  cursor: pointer;
  .TopSection {
    position: relative;
    width: 100%;
    height: 8em;
    border-radius: 13px 13px 0 0;
    overflow: hidden;
    text-align: center;
    .Photo {
      width: 100%;
      height: 100%;
    }
  }
  .Discount {
    position: absolute;
    top: 0.75em;
    left: -1px;
    z-index: 1;
    background-color: $green;
    border-radius: 0 4px 4px 0;
    font-size: 1.125em;
    line-height: 1;
    padding: 0.5em 1.5em 0.5em 1em;
  }
  .Content {
    position: relative;
    top: 0;
    width: 100%;
    padding: 1em;
    background-color: $bgfeatured;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 6px 10px #e7eaf0;
    .Title {
      position: relative;
      margin: 0 0 2em;
      text-align: left;
      line-height: unset;
      text-align: left;
      font: normal normal normal 18px/22px Yettel;
      letter-spacing: 0px;
      color: #262626;
      opacity: 1;
    }
  }
  .Price {
    position: absolute;
    top: -1.5em;
    right: 0;
    border-radius: 4px 0 0 4px;
    background-color: $blue;
    font-size: 1.125em;
    line-height: 1;
    padding: 0.5em 1em 0.5em 1.5em;
    .Amount,
    .Currency {
      color: $white;
    }
  }
  .Store {
    position: relative;
    margin: 0 0 0.25em;
    text-align: left;
    font: normal normal normal 14px/17px Yettel;
    letter-spacing: 0px;
    color: #2e2e2e;
  }
  .InfoTextWrapper {
    @include flex-center-vertical;
  }
  .BottomSection {
    @include justify-between;
  }
}

.ExpirationInfo {
  @include flex-center-vertical;
  position: relative;
  top: 0;
  width: 50%;
  text-align: left;
  margin-bottom: 2em;
  font-size: 1em;
  &.Warning {
    .ExpirationText {
      color: $lightred;
    }
  }
  .ExpirationIcon {
    position: relative;
    width: 1em;
    height: auto;
    margin-right: 0.33em;
  }
  .ExpirationText {
    position: relative;
    color: #658b0b;
    line-height: unset;
    &:last-of-type {
      margin-left: 0.33em;
    }
  }
}
