@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/media';

#root {
  background: aqua !important;
}
.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: hidden;

  > .PageTitleContainer {
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    text-align: center;
    background-color: white;

    > .PageTitle {
      text-align: center;
      font-size: 35px;
      font-weight: bold;
      margin: 2rem 0 0;
      color: $darkblue;
      opacity: 1;
      @media screen and (max-width: $maxMobile) {
        font-size: 24px;
      }
      &.NoTabs {
        margin: 2rem 0;
      }
    }
  }

  > .CenteredTabsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 2rem;
    background-color: white;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .AdListOuterContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    white-space: pre;
  }
}
