.modalWrapper {
  padding: 25px 50px;
  text-align: left;
  font-size: small;
}

.title {
  font-size: 22px;
  text-align: center;
  margin-bottom: 25px;
}

.table {
  margin-bottom: 50px;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75%;
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px;
}

.packagesDropdownWrapper {
  width: 75%;
  font-size: 18px;
}

.discountsTitle {
  font-size: 18px;
  padding: 20px 0;
}

.discountsCheckboxesWrapper {
  font-size: 16px;
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  font-size: 18px;
}

.saveChangesButton {
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  width: 200px;
  border: 2px solid var(--darkblue);
  background-color: var(--darkblue);
  color: white;
}

.rejectButton {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border: 0;
  color: var(--darkblue);
  text-decoration: underline;
  margin-top: 10px;
}
