@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/media';

.ModalContentContainer {
  display: flex;
  flex-direction: column;
  padding: 2em 4rem 0.5em;
  @media all and (max-width: $maxMobile) {
    padding: 0;
  }
  > .ContentHeader {
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    > .PickContainer {
      display: flex;
      flex-direction: row;
      width: 100%;

      .PickAllContainer {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-items: right;
        justify-content: right;

        > .PickAll {
          text-align: right;
          text-decoration: underline;
          letter-spacing: 0px;
          color: #0c1026;
          opacity: 1;
          cursor: pointer;
        }
      }

      .CancelAllContainer {
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-items: left;
        justify-content: left;

        > .CancelAll {
          text-align: left;
          text-decoration: underline;
          letter-spacing: 0px;
          color: #d80606;
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    > .PickStoresTextContainer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;

      > .PickStoresText {
        text-align: center;
        letter-spacing: 0px;
        color: #232323;
        opacity: 1;
      }
    }
  }

  > .StorePickerContainer {
    display: flex;
    flex-direction: column;
    height: 30vh;
    overflow: auto;
  }

  > .SaveButtonContainer {
    margin-top: 1em;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;

    > .SaveButton {
      background: $darkblue 0% 0% no-repeat padding-box;
      border-radius: 24px;
      opacity: 1;
      width: 50%;
      margin-left: 25%;
      margin-right: 25%;
      display: flex;
      cursor: pointer;
      flex-direction: column;

      > .SaveButtonText {
        color: white;
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }

    > .SaveButtonDisabled {
      @extend .SaveButton;
      background: grey 0% 0% no-repeat padding-box;
      cursor: not-allowed;
    }
  }
}

.OuterSearchContainer {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}
