@mixin svg-icon($path, $height, $width, $color ) {
    height: $height;
    width: $width;
    background-color: $color;
    mask-image: url($path);
    background-size: cover;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }