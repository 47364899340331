@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';
@import 'src/assets/styles/variables';

.Footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  padding: 1em 1em;
  z-index: 999;
  background-color: $footerBackground;
  .Nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .LinkWrapper {
      @include flex-center-column;
      text-decoration: none;
      position: relative;
      img {
        position: relative;
        width: 18px;
        height: 18px;
        margin: 0 auto;
      }
      .LinkText {
        color: $footerMenuInactive;
        font-family: $fontMedium;
        font-size: 14px;
        &::first-letter {
          @include uppercase;
        }
      }
      &.Active {
        .LinkText {
          color: $darkblue;
        }
      }
    }
  }
}
