@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Container {
  display: flex;
  height: fit-content;
  width: 100%;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 8px;
  border-right-width: 5px;
  border-right-style: solid;
  box-shadow: 0 6px 10px #e7eaf0;
  @media screen and (max-width: $maxMobile) {
    margin: 5px 12px;
    width: auto;
  }
  > .MainContentContainer {
    display: flex;
    flex-direction: row;
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    > .LeftContainer {
      display: flex;
      flex-direction: column;
      width: 30%;
      padding: 0.5em;
    }

    > .MiddleContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-width: 9.766vw;
      width: 100%;
      > .PricesContainer {
        @media all and (max-width: $maxMobile) {
          display: none;
        }
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        justify-items: center;
        justify-content: center;
        align-content: center;
        > .OriginalPrice {
          color: $tabsBottomBorder;
          font-family: $fontMedium;
          font-size: 0.8rem;
          margin-right: 0.5em;
          margin-top: 0.2em;
          text-decoration: line-through;
          white-space: nowrap;
        }
      }
    }

    > .RightContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-end;
      align-content: flex-end;
      margin-right: 1em;
      min-width: 14.7705078125vw;

      > .RightText {
        font-family: Yettel;
        font-size: 12px;
        letter-spacing: 0;
        text-align: right;
        align-self: flex-end;
        justify-self: flex-end;
        margin-top: auto;
      }

      > .Link {
        @extend .RightText;
        color: $darkblue;
        cursor: pointer;
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
}

.Image {
  height: 100px;
  width: 100px;
  margin: 0;
  border-radius: 8px;
  object-position: center;
  object-fit: cover;
}

.details {
  @media all and (max-width: $maxMobile) {
    font-family: Yettel-Bold;
  }
}

.Title {
  font-family: Yettel;
  font-size: 16px;
  letter-spacing: 0;
  text-align: left;
  width: 16.667vw;
  @media all and (max-width: $maxMobile) {
    margin-left: 20px;
  }
}

.Partner {
  font-family: Yettel;
  font-size: 12px;
  letter-spacing: 0;
  text-align: left;
  align-self: flex-start;
  justify-self: flex-start;
  margin-top: auto;
  @media all and (max-width: $maxMobile) {
    display: none;
  }
}

.StatusTxt {
  color: #515151;
  font-size: 12px;
}
