@import 'src/assets/styles/colors';
@import 'src/assets/styles/media';

.InputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;
  @media all and (max-width: $maxMobile) {
    margin-bottom: 1rem;
  }
  > .InputContent {
    display: flex;
    position: relative;

    > .InputField {
      width: 100%;
      cursor: pointer;
      flex-basis: 100%;
      border: 1px solid var(--lightgrey);
      padding: 1rem;
      border-radius: 8px;
      background-color: white;
      @media all and (max-width: $maxMobile) {
        line-height: 20px;
      }
      &.Disabled {
        background-color: var(--lightgrey);
        cursor: not-allowed;
      }
    }

    > .ArrowsHidden::-webkit-outer-spin-button,
    .ArrowsHidden::-webkit-inner-spin-button,
    .ArrowsHidden {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }

    > .DisplaySign {
      margin: auto 40% auto 4%;
    }

    > .InputFieldInvalid {
      @extend .InputField;
      border: 1px solid var(--darkred);
    }
  }

  .IconInputContent {
    @extend .InputContent;
    .Icon {
      height: 1.5rem;
      width: 1.5rem;
      position: absolute;
      box-sizing: border-box;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
    .InputField {
      padding-left: 70px;
    }
  }

  > .InputLabel {
    position: relative;
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
    width: 100%;
    color: $darkblue;
    font-size: 18px;
  }

  > .ErrorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;

    > .Error {
      width: 100%;
      display: flex;
      align-items: flex-start;
      color: var(--darkred);
    }
  }
}

.Required {
  color: var(--requiredstar);
}
