.Container {
  display: flex;
  flex-direction: column;
  width: 100%;
  > .Row {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid #b8b8b8;
    > .Item {
      justify-content: flex-start;
      align-content: flex-start;
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 0.5rem;
      text-align: left;
      font: normal normal normal 16px/19px Yettel;
      letter-spacing: 0px;
      color: #0c1026;
      padding-left: 2rem;
    }
    > .Price {
      justify-content: flex-end;
      align-content: flex-end;
      display: flex;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      text-align: left;
      font: normal normal normal 16px/19px Yettel;
      letter-spacing: 0px;
      color: #0c1026;
      padding-right: 2rem;
    }
  }
  > .LastRow {
    @extend .Row;
    border-bottom: none;
  }
}
