@import 'src/assets/styles/colors';

.BtnContainer {
  line-height: 1;
  border-radius: 2rem;
  background: $buttonGrey;
  text-decoration: none;
  color: var(--darkblue);
  padding: 1rem 2rem;
  cursor: pointer;
  font-weight: normal;
  margin-right: 1.5em;
}

.BtnContainerActive {
  @extend .BtnContainer;
  background: none;
  border: 2px solid var(--darkblue) !important;
}

.BtnContent {
  font-weight: normal;
  letter-spacing: 0px;
  color: #0c1026;
  opacity: 1;
}
