@import 'src/assets/styles/mixins';

.Maintenance {
  max-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  .Title {
    font-family: $fontBold;
    text-align: center;
    margin-bottom: 10px;
  }
  .Subtitle {
    text-align: center;
  }
  .Img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
  .ImgDesktop {
    width: 30%;
    height: 30%;
    margin: 0 auto;
  }
}
