@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.Content {
  position: absolute;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  height: fit-content;
  max-height: 60%;
  max-width: 80%;
  width: fit-content;
  @media all and (max-width:$maxMobile) {
    max-width: 95%;
    width: 100%;
    margin: auto;
  }
  // vertical pos
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
  // horizontal center
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.XButton {
  position: relative;
  float: right;
  margin-right: -0.5em;
  margin-top: -0.5em;
  cursor: pointer;
  @include svg-icon('../../../assets/images/icon-close.svg', 25px, 25px, black);
}
