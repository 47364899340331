@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.InfoFieldContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: transparent;

  > .InfoFieldContent {
    border-bottom: 1px solid var(--lightgrey);
    font-family: $fontBold;
    text-align: left;
    padding: 0.5em 0 0.5em 0;
    display: flex;
    justify-content: space-between;

    .Tooltip {
      background: white;
      color: #005aa4;
    }

    .IconInfo {
      width: 20px;
      height: 20px;
    }
  }

  > .InfoFieldLabel {
    position: relative;
    flex-basis: 100%;
    text-align: left;
    width: 100%;
    color: $darkblue;
  }
}
