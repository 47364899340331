@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 267px;
  width: 576px;
  margin: 0 auto;
  padding: 0;
  p {
    width: 366px;
    margin: 0 auto;
  }
  .Button {
    margin: 0 auto;
    width: 70%;
    display: flex;
    justify-content: space-between;
  }
}
