@import "src/assets/styles/colors";

.MobileHeaderMenuModal {
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: $footerBackground;
  bottom: 0;
  z-index: 99999;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
    a {
      border-bottom: solid 1px #ddd;
      display: block;
      width: 100%;
      text-align: left;
      padding: 15px;
      &:last-of-type {
        border-bottom: none;
      }
      span {
        font-size: 18px;
      }
    }
  }
}
