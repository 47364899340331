@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins/index';

.Alert {
  max-width: 360px;
  min-height: 0;
  overflow: hidden;
  transition-duration: 0.25s;
  width: 100%;

  p {
    @include font-size(14, 20, 400);
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
  }

  &:global(.Light) {
    &:global(.Success) {
      .Wrapper {
        background-color: $colorSuccessBackground;
        p {
          color: $colorSuccess;
        }
      }
      .CloseIcon {
        svg {
          fill: $colorSuccess;
        }
      }
    }

    &:global(.Info) {
      .Wrapper {
        background-color: $colorInfoBackground;
        p {
          color: $colorInfo;
        }
      }
      .CloseIcon {
        svg {
          fill: $colorInfo;
        }
      }
    }

    &:global(.Warning) {
      .Wrapper {
        background-color: $colorWarningBackground;
        p {
          color: $colorWarning;
        }
      }
      .CloseIcon {
        svg {
          fill: $colorWarning;
        }
      }
    }

    &:global(.Danger) {
      .Wrapper {
        background-color: $colorDangerBackground;
        p {
          color: $colorDanger;
        }
      }
      .CloseIcon {
        svg {
          fill: $colorDanger;
        }
      }
    }
  }

  &:global(.Dark) {
    .Wrapper {
      p {
        color: $colorTextLight;
      }
    }
    .CloseIcon {
      svg {
        fill: $colorTextLight;
      }
    }

    &:global(.Success) {
      .Wrapper {
        background-color: $colorSuccessBackgroundDark;
      }
    }

    &:global(.Info) {
      .Wrapper {
        background-color: $colorInfoBackgroundDark;
      }
    }

    &:global(.Warning) {
      .Wrapper {
        background-color: $colorWarningBackgroundDark;
      }
    }

    &:global(.Danger) {
      .Wrapper {
        background-color: $colorDangerBackgroundDark;
      }
    }
  }
}

.Wrapper {
  border-radius: 8px;
  display: flex;
  padding: 1.25rem 2.25rem 1.25rem 1.25rem;
  position: relative;
}

.CloseIcon {
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;

  svg {
    height: 0.75rem;
    width: 0.75rem;
  }
}

.InfoIcon {
  margin-right: 0.75rem;
  svg {
    //fill: white;

    path {
      fill: white;
    }
  }
}
