@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.Btn {
  position: relative;
  margin: 0 auto;
  background: none;
  border: none;
  font-size: 1.125em;
  letter-spacing: 1px;
  color: var(--darkblue);
  line-height: 1;
  background-color: #b4ff00;
  border-radius: 24px;
  padding: 1em 3em;
  transition: opacity 0.3s;
  border: #b4ff00 2px solid;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
}

.BtnDisabled {
  @extend .Btn;
  background-color: var(--lightgrey);
  cursor: not-allowed;
}
