@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.AgeSlider {
  height: 150px;
  width: 700px;
  background-color: white;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 100;
  margin: 0 auto;
  padding: 20px 30px 55px 30px;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #e5e9ec;
  .Slider {
    background-color: red;
  }
}

.filterHeader {
  display: flex;
  gap: 0.5em;
  padding: 10px 10px 10px 0;
  align-items: center;
}

.filterIcon {
  margin-right: 0;
}
