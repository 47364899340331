.ForgotPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  .ForgotPassword {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: fit-content;
  }
}

.OuterInputFieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

.ForgotPasswordBtnContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}
