@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

/* STANDARD OFFERS */

.StandardOffer,
.ExpiredCoupon {
  position: relative;
  width: 100%;
  background-color: $white;
  box-shadow: 0 6px 10px #e7eaf0;
  border: 1px solid $lightgrey;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  color: $darkblue;
  margin-bottom: 1rem;
  cursor: pointer;
  overflow: hidden;
  .YettelPayRibbon {
    top: 0.5rem;
  }
  .PhotoWrapper {
    @include flex-alignitems-flex-start;
    position: relative;
    flex-basis: 30%;
    min-height: 100%;
    .Photo {
      width: 100%;
      height: 100%;
    }
  }

  .Content {
    position: relative;
    flex-basis: 70%;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;

    .TitleWrapper {
      @include justify-between;
      @include flex-alignitems-flex-start;
      width: 100%;
    }

    .Title {
      position: relative;
      flex-basis: 100%;
      margin: 0;
      font-size: 1rem;
      text-align: left;
      line-height: unset;
      height: 4.5rem;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .Store {
      font-size: 0.9rem;
      line-height: unset;
      color: $storeName;
      width: 100%;
    }
    .Info {
      @include flex-center-vertical;
      @include justify-between;
      width: 100%;
      .OriginalPrice {
        color: $border;
        font-family: $fontMedium;
        font-size: 0.8rem;
        text-decoration: line-through;
        margin-right: 0.5rem;
        white-space: nowrap;
      }
      .Price {
        .Amount {
          font-family: $fontMedium;
          color: $standardOfferPrice;
          white-space: nowrap;
        }
      }
    }
  }
}

.StandardOffer {
  .Content {
    .Store {
      text-align: right;
    }
    .Info {
      @include flex-end;
    }
  }
}

.ExpiredCoupon {
  .Content {
    .Store {
      text-align: left;
    }
    .Info {
      @include justify-between;
    }
  }
}

/* YETTEL PAY RIBBON */

.YettelPayRibbon {
  position: absolute;
  left: -1px;
  z-index: 1;
}
