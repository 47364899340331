@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

.OptionLabel {
  display: flex;
  align-items: center;
  padding-left: 5%;

  .ImageLabel {
    height: 2em;
    width: 2em;
    margin: 0;
  }
}

.Option {
  display: flex;
  align-items: center;
}

.SelectContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  > .SelectContent {
    border: 1px solid var(--lightgrey);
    border-radius: 8px;
    background: white;
    display: flex;
    position: relative;

    .Image {
      height: 2em;
      width: 2em;
    }

    > .SelectField {
      width: 100%;
      flex-basis: 100%;
      background: none;
      border: 1px solid var(--lightgrey);
      padding: 1rem;
      border-radius: 8px;
      appearance: none;
    }
  }

  > .SelectLabel {
    color: $darkblue;
    flex-basis: 100%;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .SelectArrowsContainer {
    // pointer-events: none;
    position: relative;
    z-index: 2;
    position: absolute;
    box-sizing: border-box;
    top: 60%;
    right: 0;

    .SelectArrowsInnerContainer {
      float: right;
      margin-right: 0.5em;

      > .UpArrow {
        @include svg-icon('../../icons/dropdown-up.svg', 6.5px, 5px, #373a3c);
      }

      > .DownArrow {
        @include svg-icon('../../icons/dropdown.svg', 6.5px, 5px, #373a3c);
      }
    }
  }
}

.ErrorContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;

  > .Error {
    width: 100%;
    display: flex;
    align-items: flex-start;
    color: var(--darkred);
  }
}

.Required {
  color: var(--requiredstar);
}
