@import 'src/assets/styles/colors';
@import 'src/assets/styles/mixins';

$infoItemPadding: 8px 0;

.SellerInfoSection {
  margin-top: 2rem;
  text-align: center;
  .Content {
    padding: $textSectionPadding;
  }
  .Title {
    text-align: left;
    margin: 0;
    padding: 0 0 8px;
    font-family: $fontMedium;
  }
  .Address {
    text-align: left;
    margin-bottom: 0;
    padding: $infoItemPadding;
    font-family: $fontMedium;
  }
  .InfoItem {
    @include justify-between;
    position: relative;
    width: 100%;
    padding: $infoItemPadding;
    border-bottom: 1px solid $lightgrey;
    .InfoItemTitle {
      font-family: $fontRegular;
      white-space: nowrap;
      text-align: left;
    }
    .InfoItemData {
      text-align: right;
      word-break: break-all;
      margin-left: 2rem;
      p, a, span {
        font-family: $fontMedium;
        margin-bottom: 0.5rem;
      }
    }
    .Link {
      color: $darkblue;
      cursor: pointer;
    }
    &:first-of-type {
      border-top: 1px solid $lightgrey;
    }
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }
}
