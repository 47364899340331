@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

$contentWidth: 1182px;
.Obligation {
  margin: 20px 0 0 10px;
  display: flex;
  align-items: flex-start;
  strong {
    padding-right: 5px;
  }
}
.PromoPeriod {
  height: 420px;
  background-color: #cae9f5;
  padding-top: 50px;
  .PromoPeriodHeader {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 876px;
    margin: 0 auto;
    .PromoPeriodTitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font-size: 40px;
        margin: 0;
      }

      p {
        margin: 0;
        margin-top: 5px;
        font-size: 20px;
      }
    }
    img {
      height: 104px;
    }
  }
  .Content {
    width: 876px;
    margin: 0 auto;
    text-align: left;
    margin-top: 42px;
  }

  .CheckMarks {
    width: 876px;
    display: flex;
    margin: 0 auto;
    margin-top: 56px;
    justify-content: flex-start;
    align-items: center;
    img {
      height: 31px;
      margin-right: 12px;
    }
    p {
      font-size: 18px;
      font-family: 'Yettel-Bold';
      margin: 0;
      margin-right: 20px;
    }
  }
}

.Text {
  padding-top: 30px;
  width: $contentWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  text-align: left;
}

.ContractExpired {
  width: $contentWidth;
  height: 60px;
  background-color: #ffe9d3;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
}
.TariffPackagesWithAddition {
  border-bottom: 2px solid #707070;
  width: 1182px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.TariffPackages {
  padding: 0;
  margin: 0;
  border: none;
}
// PayAsYouGo styling

.PayAsYouGoContent {
  * {
    margin: 0;
    padding: 0;
  }
  .Box4RequestSent {
    @extend .Box4;
    background-color: #fff6cf;
    padding: 30px 0 47px 0;
    width: 236px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .WaitingForApproval {
    display: flex;
    align-items: center;
    img {
      padding-right: 5px;
    }
  }
  width: $contentWidth;
  margin: 0 auto;
  background-color: #fff;
  height: 215px;
  border-radius: 10px;
  box-shadow: 0px 10px 10px #9f9f9f29;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden; /* required */

  .Ribbon {
    margin: 0;
    padding: 0;
    background: #b4ff00;
    position: absolute;
    box-shadow: 0px 3px 6px #00000029;
    top: 40px;
    left: 20px;
    transform: translateX(-30%) translateX(0%) rotate(-35deg);
    transform-origin: top left;
    p {
      margin: 0;
    }
  }
  .Ribbon:before,
  .Ribbon:after {
    content: '';
    position: absolute;
    top: 0;
    margin: 0 -1px;
    width: 100%;
    height: 100%;
    background: #b4ff00;
    box-shadow: 0px 3px 6px #00000029;
  }
  .Ribbon:before {
    right: 100%;
  }

  .Ribbon:after {
    left: 100%;
  }
  .Box1 {
    * {
      margin: 0;
    }
    margin: 0;
    width: 292px;
    height: 131px;
    border-right: 2px solid #002340;
    margin-top: 25px;
    margin-bottom: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    .Content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .Title {
        margin-bottom: 20px;
        font-family: 'Yettel-Bold';
      }
      .Description {
        margin-bottom: 15px;
      }
      .Price {
        display: flex;
        justify-content: center;
        align-items: center;
        .BigNumber {
          font-family: 'Yettel-Bold';
          font-size: 50px;
        }
      }
    }
  }

  .Box2 {
    @extend .Box1;
    width: 360px;
    .Content {
      width: 100%;
      .TitleWrapper {
        display: flex;
        justify-content: flex-start;
        img {
          height: 26px;
        }
        .Title {
          margin-left: 32px;
          margin-right: 5px;
        }
      }
      .FlexContent {
        width: 80%;
        margin-left: 32px;
        display: flex;
        justify-content: space-between;
        .Price {
          justify-content: flex-start;
        }
      }
    }
  }

  .Box3 {
    @extend .Box2;
    border: none;
    width: 350px;
    .TextContent {
      p {
        text-align: left;
        margin-left: 32px;
      }
    }
  }
}
.Box4Blank {
  width: 236px;
  height: 100%;
  background-color: #dcf1f9;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 45px;
}

.Box4 {
  width: 236px;
  justify-content: center;
  padding: 40px 0;
  .Price {
    .PriceValue {
      font-family: 'Yettel-Bold';
      font-size: 33px;
      margin-bottom: 17px;
      .Currency {
        font-size: 18px;
        font-family: 'Yettel';
      }
    }
  }
}

//MonthlyPayment styling

.MonthlyPaymentContent {
  @extend .PayAsYouGoContent;
  * {
    margin: 0;
    padding: 0;
  }
  border: none;
  .Box1 {
    .Content {
      height: 131px;
      .TitleHeading {
        font-size: 30px;
        font-family: 'Yettel-Bold';
      }
    }
  }

  .Box2 {
    .Content {
      height: 131px;
      text-align: left;
    }
    .FlexContent {
      .TitleHeading {
        .TitleAddition {
          display: block;
          font-size: 22px;
          margin-top: 10px;
        }
      }
    }
  }
  .Box4 {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 40px 0;
    padding-bottom: 45px;
    align-items: center;
    justify-content: space-between;
    background-color: #dcf1f9;
    width: 236px;

    p {
      margin: 0;
    }
    .Prices {
      display: flex;
      .Values {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 70px;
        .CrossedPrice {
          text-decoration: line-through;
          font-size: 16px;
          margin-right: 3px;
        }
        .NewPriceValue {
          font-family: 'Yettel-Bold';
          font-size: 32px;
          margin-bottom: 7px;
        }
      }
      .Currencies {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 8px;
        .OldPriceCurrency {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 8px;
          .Rsd {
            margin-bottom: -3px;
          }
        }
        .NewPriceCurrency {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .Rsd {
            margin-bottom: -10px;
          }
        }
      }
    }
    .OldPrice {
      display: flex;
      .Currency {
        display: flex;
        flex-direction: column;
        span {
          font-size: 8px;
        }
      }
      .CrossedPrice {
        text-decoration: line-through;
        font-size: 16px;
      }
    }
    .NewPrice {
      @extend .OldPrice;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .NewPriceValue {
        font-family: 'Yettel-Bold';
        font-size: 32px;
        margin-bottom: 7px;
      }
      .Currency {
        display: flex;
        flex-direction: column;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .Box4RequestSent {
    @extend .Box4;
    background-color: #fff6cf;
    padding: 30px 0 47px 0;
    width: 236px;
  }
}

//MonthlyPaymentAddition styling

.MonthlyPaymentAdditionContent {
  @extend .MonthlyPaymentContent;
  .FullWidthBox {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 956px;
    .Title {
      font-family: 'Yettel-Bold';
      font-size: 30px;
    }
    .Description {
      font-size: 14px;
    }
  }
  .Box4Addition {
    @extend .Box4;
    width: 225px;
    justify-content: center;
    padding-top: 100px;
    .Price {
      .PriceValue {
        font-family: 'Yettel-Bold';
        font-size: 33px;
        margin-bottom: 17px;
        .Currency {
          font-size: 18px;
          font-family: 'Yettel';
        }
      }
    }
  }
  .Box4AdditionActive {
    @extend .Box4Addition;
    background-color: #b4ff00;
    padding-bottom: 60px;
  }
}
.ActivePackage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.Active {
  height: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center;
  background-color: #b4ff00 !important;
  width: 236px;
  img {
    height: 24px;
    margin-right: 6px;
  }
  p {
    font-size: 18px;
    margin: 0;
  }
  .WaitingForApproval {
    display: flex;
    align-items: center;
    img {
      padding-right: 5px;
    }
  }
}

.ActiveBorder {
  border: 2px solid #b4ff00 !important;
}
