@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.Container {
  display: flex;
  flex-direction: column;
  > .Title {
    font-family: $fontPrimary;
    color: var(--darkblue);
    font-weight: bold;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 1.5em;
  }
  .ModalHeader {
    text-align: left;
    margin-bottom: 1rem;
  }
  .InputFieldContainer {
    margin-bottom: 1rem;
  }
  > .SaveBtnContainer {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-items: center;
    align-items: center;
  }
}

