.wrapper {
  width: 1000px;
  margin: 20px auto;
  text-align: left;
}

.finalSum {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  background: white;
  font-family: Yettel-Bold;
  margin-top: 75px;
}

.isPromoPeriodMessage {
  text-align: center;
  margin-top: 100px !important;
}
